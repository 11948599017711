
import { UPDATE_PUB } from '../constants/ActionTypes';



export const updatePub = (pub,isUpdate) => {
    return {
        type: UPDATE_PUB,
        payload: {pub, isUpdate}
    };
};
