
import axios from 'axios';
import config from '../../config';
import { getAxiosConfigUserToken } from '../../util/utilFunction';

export const getPubReviewAllApi = async()=>{
    const url = `${config.apiUrl}/v1/pubReview/`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getPubReviewByPubIdApi = async(pubId)=>{
   const url = `${config.apiUrl}/v1/pubReview/byPubId/${pubId}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
export const getPubReviewByIdApi = async(id)=>{
   const url = `${config.apiUrl}/v1/pubReview/${id}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
export const getPubReviewFullByPubIdApi = async(pubId)=>{
   //buradaki fark hakem isimleride var.
   const url = `${config.apiUrl}/v1/pubReview/full/byPubId/${pubId}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}

export const getPubReviewByUserIdApi = async(userId)=>{
    const url = `${config.apiUrl}/v1/pubReview/byUserId/${userId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getPubReviewByRefereeIdApi = async(refereeId)=>{
    const url = `${config.apiUrl}/v1/pubReview/byRefereeId/${refereeId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
export const deletePubReviewByPubIdApi = async(pubId)=>{
    const url = `${config.apiUrl}/v1/pubReview/byPubId/${pubId}`;
    return await axios.delete(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const deletePubReviewByIdApi = async(id)=>{
    const url = `${config.apiUrl}/v1/pubReview/${id}`;
    return await axios.delete(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const postSavePubReviewApi = (PubReview)=>{
    const url = `${config.apiUrl}/v1/pubReview/`;
    return axios.post(url,PubReview,getAxiosConfigUserToken())
    .then(response => response.data);
 }
 export const putUpdatePubReviewApi = (PubReview)=>{
    const url = `${config.apiUrl}/v1/pubReview/`;
    return axios.put(url,PubReview,getAxiosConfigUserToken())
    .then(response => response.data);
 }
 export const putUpdatePubReviewByPubIdApi = (PubReview)=>{
   const url = `${config.apiUrl}/v1/pubReview/byPubId/`;
   return axios.put(url,PubReview,getAxiosConfigUserToken())
   .then(response => response.data);
}
export const putUpdatePubReviewByPubIdAndDecisionApi = (PubReview,decision)=>{
   const url = `${config.apiUrl}/v1/pubReview/byPubIdAndDecision/${decision}`;
   return axios.put(url,PubReview,getAxiosConfigUserToken())
   .then(response => response.data);
}
 export const postSavePubReviewListApi = (pubReviewList)=>{
    const url = `${config.apiUrl}/v1/pubReview/list/`;
    return axios.post(url,pubReviewList,getAxiosConfigUserToken())
    .then(response => response.data);
 }
 


 export const getPubReviewReportPDFByIdApi = (id,lang,visibility)=>{
   const url = `${config.apiUrl}/v1/pdf/pubReview/${id}/?lang=${lang}&visibility=${visibility}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}


export const postPubReviewByRefereeIdAndDecisionListApi = (refereeIdAndDecisionList)=>{
    const url = `${config.apiUrl}/v1/pubReview/byRefereeIdAndDecisionList/`;
    return axios.post(url,refereeIdAndDecisionList,getAxiosConfigUserToken())
    .then(response => response.data);
 }
 export const postPubReviewAndPubInfoByRefereeIdAndDecisionListApi = (refereeIdAndDecisionList)=>{
   const url = `${config.apiUrl}/v1/pubReview/pubInfo/byRefereeIdAndDecisionList/`;
   return axios.post(url,refereeIdAndDecisionList,getAxiosConfigUserToken())
   .then(response => response.data);
}

export const getPubReviewByCorrectionCodeApi = async(CorrectionCode)=>{
   const url = `${config.apiUrl}/v1/pubReview/byCorrectionCode/${CorrectionCode}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}

export const getPubReviewRefereeDocumentPDFByIdApi = (id,lang)=>{
   const url = `${config.apiUrl}/v1/pdf/pubReviewRefereeDocument/${id}/?lang=${lang}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}

export const postPubReviewStatisticsRefereeByRefereeIdApi = async(refereeIdList)=>{
   const url = `${config.apiUrl}/v1/pubReview/statisticsReferee/byRefereeIdList/`;
   return await axios.post(url,{refereeIdList},getAxiosConfigUserToken())
   .then(response => response.data)
}