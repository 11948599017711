import {
  
    UPDATE_PUB,

} from "constants/ActionTypes";

const INIT_STATE = {
    pub: null,
    isUpdate: false,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_PUB: {
            return {
                ...state,
                pub: action.payload.pub,
                isUpdate: action.payload.isUpdate,
            }
        }
        default:
            return state;
    }
}
