import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { getDateLocale, getFullName } from '../../util/utilFunction';
import config from '../../config';

const NotificationItem = ({notification,selectedReplyMessage,selectedReadMessage}) => {
  const { badge, name, createDate, body,profileImageUrl} = notification;
  const image = config.userAuthApiUrl + profileImageUrl;
  return (
    <li className="media"> 
      <div className="user-avatar">
        <Avatar
          alt={name} 
          src={image}
        />
        <span className="badge badge-danger rounded-circle">{badge}</span>
      </div>
      <div className="media-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-capitalize user-name mb-0"><span className="jr-link" onClick={()=>selectedReplyMessage(notification)}>{getFullName(notification)}</span></h5>
          <span className="meta-date"><small>{getDateLocale(createDate)}</small></span>
        </div>
        <p className="sub-heading">{body.substring(0, 25) + "..."}</p>
        <Button className="jr-btn jr-btn-xs text-muted" onClick={()=>selectedReplyMessage(notification)}><i className="zmdi zmdi-mail-reply"/><span><IntlMessages id="button.reply"/></span></Button>
        <Button className="jr-btn jr-btn-xs text-muted" onClick={()=>selectedReadMessage(notification)}><i className="zmdi zmdi-eye"/><span><IntlMessages id="button.read"/></span></Button>
      </div>
    </li>
  );
};

export default NotificationItem;
