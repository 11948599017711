import arrayMove from "array-move";
// import todoConversation from 'app/routes/todo/data/todoConversation';
// import messages from 'app/routes/todo/data/todo';
import {
  FETCH_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES,
  GET_IMPORTANT_MESSAGES,
  GET_MARK_AS_START,
  GET_STARRED_MESSAGES,
  GET_UNSELECTED_ALL_MESSAGES,
  GET_UNSTARRED_MESSAGES,
  ON_DELETE_MESSAGES,
  ON_MESSAGES_ADD,
  ON_MESSAGES_UPDATE,
  ON_SORTEND,
  SEARCH_MESSAGES,

  SET_INBOX_MESSAGES, SHOW_MESSAGE
} from "constants/ActionTypes";
import { UPDATE_MESSAGES } from "../constants/ActionTypes";

const INIT_STATE = {
    newMessageSize : null ,
  searchMessages: "",
  alertMessage: "",
  loader: true,
  showMessage: false,
  optionName: "None",
  drawerState: false,
  inboxMessages: [],
  sendboxMessages: [],
  currentMessages: null,
  messages: [],

};

export default (state = INIT_STATE, action) => {

 //console.log("action ve state messahes ",action , state)

  switch (action.type) {
    case ON_SORTEND: {
      return {
        ...state,
        messages: arrayMove(
          state.messages,
          action.payload.oldIndex,
          action.payload.newIndex
        ),
      };
    }
 
    case GET_ALL_MESSAGES: {
      return { 
        ...state,
        currentMessages: null,
        messages: state.allMessages,
      };
    }
    case GET_UNSELECTED_ALL_MESSAGES: {
      let messages = state.allMessages.map((todo) =>
        todo
          ? {
              ...todo,
              selected: false,
            }
          : todo
      );
      return {
        ...state,
        selectedToDos: 0,
        allMessages: messages,
        optionName: "None",
        messages: messages,
      };
    }
    case GET_STARRED_MESSAGES: {
      let selectedToDos = 0;
      let messages = state.allMessages.map((todo) => {
        if (todo.starred) {
          selectedToDos++;
          return { ...todo, selected: true };
        }
        return { ...todo, selected: false };
      });
      return {
        ...state,
        selectedToDos: selectedToDos,
        allMessages: messages,
        optionName: "Stared",
        messages: messages.filter((todo) => !todo.deleted),
      };
    }
    case GET_UNSTARRED_MESSAGES: {
      let selectedToDos = 0;
      let messages = state.allMessages.map((todo) => {
        if (!todo.starred) {
          selectedToDos++;
          return { ...todo, selected: true };
        }
        return { ...todo, selected: false };
      });
      return {
        ...state,
        selectedToDos: selectedToDos,
        allMessages: messages,
        optionName: "UnStarred",
        messages: messages.filter((todo) => !todo.deleted),
      };
    }
    case GET_IMPORTANT_MESSAGES: {
      let selectedToDos = 0;
      let messages = state.allMessages.map((todo) => {
        if (todo.important) {
          selectedToDos++;
          return { ...todo, selected: true };
        }
        return { ...todo, selected: false };
      });
      return {
        ...state,
        selectedToDos: selectedToDos,
        allMessages: messages,
        optionName: "Important",
        messages: messages.filter((todo) => !todo.deleted),
      };
    }

    case GET_MARK_AS_START: {
      const messages = state.allMessages.map((todo) => {
        if (todo.id === action.payload.id) {
          return action.payload;
        } else {
          return todo;
        }
      });
      return {
        ...state,
        alertMessage: "ToDo Updated Successfully",
        showMessage: true,
        allMessages: messages,
        messages: messages,
      };
    }

    case ON_MESSAGES_UPDATE: {
      const messages = state.allMessages.map((todo) => {
        if (todo.id === action.payload.id) {
          return action.payload;
        } else {
          return todo;
        }
      });
      return {
        ...state,
        alertMessage: "ToDo Updated Successfully",
        showMessage: true,
        currentMessages: action.payload,
        allMessages: messages,
        messages: messages,
      };
    }
    case ON_DELETE_MESSAGES: {
      let selectedToDos = 0;
      const messages = state.allMessages.map((todo) => {
        if (todo.selected) {
          selectedToDos++;
        }
        if (action.payload.id === todo.id) {
          if (todo.selected) {
            selectedToDos--;
          }
          return { ...todo, deleted: true };
        } else {
          return todo;
        }
      });
      return {
        ...state,
        alertMessage: "ToDo Deleted Successfully",
        showMessage: true,
        allMessages: messages,
        currentMessages: null,
        selectedToDos: selectedToDos,
        messages: messages.filter((todo) => !todo.deleted),
      };
    }

    case SEARCH_MESSAGES: {
      if (action.payload === "") {
        return {
          ...state,
          messages: state.allMessages.filter((todo) => !todo.deleted),
        };
      } else {
        const searchToDos = state.allMessages.filter(
          (todo) =>
            !todo.deleted &&
            todo.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return { ...state, messages: searchToDos };
      }
    }

    case ON_MESSAGES_ADD: {
      return {
        ...state,
        messages: state.allMessages.concat(action.payload),
        allMessages: state.allMessages.concat(action.payload),
      };
    }

    case FETCH_ALL_MESSAGES_SUCCESS: {
//console.log(" MESAJ action.payload ", state,action.payload);
      let newInboxMessages = [];
      let newSendboxMessages = [];

      let inboxMessages = action.payload.inboxMessages;
      let sendboxMessages = action.payload.sendboxMessages;

      let newMessageSize = inboxMessages.reduce((a,x) => { return x.isRead === 0 ?  a + 1 : a  } , 0 );

      inboxMessages.map((x)=>{ 
        x.badge = x.isRead === 0 ? 1 : null;
          return x ; 
    })
    newInboxMessages = inboxMessages;
    newSendboxMessages = sendboxMessages;
//burası simdilik devre dışı bırakıldı. 18.03.2021 

//     const grouped = groupBy(inboxMessages, pet => pet.questionId); 

//     newInboxMessages.push(...grouped.get(0));

//     grouped.forEach((value, key) => {
//       let badge = value.reduce((a,x) => { return x.isRead === 0 ?  a + 1 : a  } , 0 );
//       if(key !== 0)
//       {
//         newInboxMessages.push({...value[0] , badge : badge !== 0 ? badge : null})
//       }

//   })
//   const grouped2 = groupBy(sendboxMessages, pet => pet.questionId); 

//   newSendboxMessages.push(...grouped.get(0));
//   grouped2.forEach((value, key) => {
//     let badge = value.reduce((a,x) => { return x.isRead === 0 ?  a + 1 : a  } , 0 );
//     if(key !== 0)
//     {
//       newSendboxMessages.push({...value[0] , badge : badge !== 0 ? badge : null})
//     }
// })


  // console.log("newInboxMessages ",newInboxMessages)
      return {
        ...state,
        messages: action.payload, 
        inboxMessages:newInboxMessages,
        sendboxMessages: newSendboxMessages,
        loader: false,
        newMessageSize,
        // alertMessage: newMessageSize +" Tane yeni mesaj var.",
        // showMessage: true,
      };
    }

    case SHOW_MESSAGE: {
     ////console.log("SHOW_MESSAGE ", action.payload);
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      }; 
    }

    case UPDATE_MESSAGES:{
      return {
        ...state,
        ...action.payload
      };
    }

    case SET_INBOX_MESSAGES: {
     //console.log("action.payload ", action.payload);
       let newInboxMessages = [...action.payload.inboxMessages];
 
       let inboxMessages = action.payload.inboxMessages;
       let newMessageSize = 0;
       inboxMessages.map((x,index)=>{
 
         if(x.questionId !== 0){
          
            
           if(x.questionId === newInboxMessages[index-1].questionId)
          {
             x.badge = newInboxMessages[index-1].badge + 1 
           newInboxMessages[index-1] = x;
           newInboxMessages.splice(index,1);
         }
         else
         {
           x.badge =  1 
         }
        
         }
        
         newMessageSize = newMessageSize + 1;
 
         return x ;
 
        
     })
 
    //console.log("newInboxMessages,newInboxMessages",newInboxMessages)
 
 
       return {
         ...state,
         messages: action.payload,
         inboxMessages:newInboxMessages,
         newMessageSize,
       };
     }


    default:
      return state;
  }
};
