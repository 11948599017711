import React from 'react';

import NotificationItem from './NotificationItem';
import CustomScrollbars from 'util/CustomScrollbars';

const AppNotification = ({notifications,setReadNotification}) => {
  return (
    <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
      <ul className="list-unstyled">
        {notifications.map((notification, index) => <NotificationItem key={index} notification={notification} setReadNotification={setReadNotification}/>)
        }
      </ul>
      {
        notifications.length === 0 && <span className="text-center m-3 bold">
          Yeni Bildirim Yok
        </span>
      }
    </CustomScrollbars>
  )
};

export default AppNotification;

