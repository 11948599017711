import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Alert } from "@material-ui/lab";
import {
  hideMessage,
  showAuthLoader,



  userSignIn
} from "actions/Auth";
import CardBox from "components/CardBox";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isIOS, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { getPubReviewByCorrectionCodeApi } from "../api/pubReview";
import { getDateLocale2 } from "../util/utilFunction";


class PubReviewCorrect extends React.Component {
  constructor() {
    super();
    this.state = {
      pubReview: {},
      lock: true,
      error:false,
    };
  }

  async componentDidMount() {
    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const cd = params.get("cd");

    if(cd.length>4)
    {

      const result = await getPubReviewByCorrectionCodeApi(cd);
     //console.log("resut ",result)
      if(result.status)
      {
        if (result.data.length>0) 
        {
          this.setState({ lock: false , pubReview:result.data[0]});
        }
       
      }

    }

    // const result = await getPubByUidApi(uid);
    // if (result.status) {
    // // //console.log("444444444444444 ", result);
    //   this.setState({ pub: result.data.pub, process: result.data.process });
    // }


  }


  getResultByCorrectCode = async(correctCode) =>{

    const result = await getPubReviewByCorrectionCodeApi(correctCode);
   //console.log("resut ",result)
    if(result.status)
    {
      if (result.data.length>0) 
      {
        this.setState({ lock: false , pubReview:result.data[0]});
      }
      else 
      this.setState({error:true})
    }


  }

  render() {
    const { pubReview, lock } = this.state;

    if (isIOS || isMobile) {
      document.body.classList.add("ios-mobile-view-height");
   //  //console.log("--------------------------------------------- ",isIOS, isMobile)
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
   //  //console.log("--------------------------------------------- ",isIOS, isMobile)
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
   
   
       
            {!lock ? (
              <div className="overflow-auto">
                <div className="row justify-content-center mb-3">
                  <Link to="/">
                    <img
                      src={require("assets/images/yaysis_logo/sonLogo2.png")}
                      alt="Yaysis"
                      title="Yayın Takip Sistemi"
                    />
                  </Link>
                </div>

                <CardBox
                  styleName="col-md-12 col-xs-12 col-sm-12"
                  heading={<IntlMessages id="pub.information" />}
                >
                  <List dense={true}>
                    <Divider light />

                    <ListItem>
                      <ListItemText
                        color="teal"
                        primary={<IntlMessages id="institution" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={  <IntlMessages id={"institution."+pubReview.institution} />}
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        color="teal"
                        primary={<IntlMessages id="referee.title" />}
                        primaryTypographyProps={{ color: "secondary"  }}
                        secondaryTypographyProps={{  fontWeight:'bold' , fontSize:14}}

                        secondary={pubReview.refereeName}
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        color="teal"
                        primary={<IntlMessages id="pub.title" values={{lang:""}}  />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={pubReview.title}
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pubReview.reviewDate" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                        getDateLocale2("tr",pubReview.reviewDate)
                        }
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pubReview.correctionCode" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                          pubReview.correctionCode
                        }
                      />
                    </ListItem>
                   
                    <Divider light />
                    
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pub.type" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                          pubReview.type===1 ? <IntlMessages id={"book"} /> : <IntlMessages id={"article"} />
                        }
                      />
                    </ListItem>
                    <Divider light />
                
                { pubReview.type===2 &&
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pub.journalId" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                          <IntlMessages id={"journal."+pubReview.journalId} /> 
                        }
                      />
                    </ListItem>}
                    <Divider light />
                  </List>

                </CardBox>

              
              </div>
            ) : (
              <div className="bg-grey"></div>
            )} 
   </div>
            <SweetAlert
              show={this.state.lock}
              input
              confirmBtnText={<IntlMessages id="button.ok" />}
              confirmBtnBsStyle="danger"
              title={<IntlMessages id="pubReviewCorrect.alert.title" />}
              validationMsg={<IntlMessages id="pubReviewCorrect.alert.msg" />}
              onConfirm={(text) => {
             //  //console.log("tettt ", text);
                this.getResultByCorrectCode(text)
               
              }}
              //  style={{backgroundColor:'red'}}
              hideOverlay={false}
               
            >


              <div className="row justify-content-center mb-3">
                <Link className="" to="/">
                  <img
                    src={require("assets/images/yaysis_logo/sonLogo2.png")}
                    alt="Yaysis"
                    title="Yayın Takip Sistemi"
                  />
                </Link>
              </div>

              {
                this.state.error && <Alert className="mb-2" severity="error">
<IntlMessages id="pubReviewCorrect.alert.error" />
                </Alert>
              }
            </SweetAlert>
{/* 
            <SweetAlert
              show={this.state.error}
              danger
              confirmBtnText={<IntlMessages id="button.ok" />}
              confirmBtnBsStyle="danger"
              title={<IntlMessages id="showPub.alert.error" />}
              onConfirm={() => {
                this.setState({error:false})
              }}
            
            /> */}
            

          </div>
 
  
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(PubReviewCorrect);
