import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState,useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import IntlMessages from 'util/IntlMessages';
import { updateMessages } from '../../../../actions/Messages';
import { getDateLocaleShortAndDateShort } from '../../../../util/utilFunction';
import UserCell from './UserCell';
import { putUpdateWebMessagesApi } from '../../../../api/webMessages';


 

function ReadMessageModal({open, onClose, senderUser,receiverUser,selectedMessage,selectedReplyMessage,messages,updateMessages}) {
  const [success,setSuccess] = useState(false);

  const getAPi = async ()=>{
    const result = await putUpdateWebMessagesApi({id: selectedMessage.id , isRead:1 })
 console.log("result ",result)
 console.log("Mesaj okuma 1 kez çalıştı ");

  if(result.status){
   
    let newMessagesRedux = {
      ...messages,
      newMessageSize : messages.newMessageSize - 1 ,
      inboxMessages : messages.inboxMessages.map(x=>{
        if(x.id === selectedMessage.id)
        {
          x.isRead = 1 ;
          x.badge = null ;
        }
        return x;
      })
    };
    console.log("messages , ",messages , newMessagesRedux)
    updateMessages(newMessagesRedux)

   //console.log("newMessagesRedux ",newMessagesRedux)

  }
  }

  useEffect(() => {
    getAPi();
    
}, [selectedMessage])



  return (
    <Modal className="modal-box modal-box-mail" isOpen={open}  style={{zIndex: 2600}}>
      <ModalHeader className="modal-box-header bg-primary text-white">
      <IntlMessages id="webMessages.readMessageModal.title"/>
        <IconButton className="text-white"
                    onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </ModalHeader>
      <div className="modal-box-content d-flex flex-column">

    
         <UserCell user={senderUser} title={<IntlMessages id="webMessages.sender"/>} />
         <UserCell user={receiverUser} title={<IntlMessages id="webMessages.receiver"/>} />

        { selectedMessage !== null && <Alert icon={<MailIcon fontSize="inherit" />} severity="info" className="mt-3">
         <AlertTitle><IntlMessages id="webMessages"/>:</AlertTitle>
  {selectedMessage.body}  — <strong>{getDateLocaleShortAndDateShort("tr",selectedMessage.createDate)}</strong>
</Alert>}

      </div> 

      <div className="modal-box-footer">
     

        <Button variant="contained"  className="mr-3"  color="primary" onClick={() => {
        //  e.preventDefault()
       //  //console.log("onclose a tıkladı")
          onClose();   
        }}>
          <IntlMessages id="button.close"/></Button>

          <Button variant="contained" className="mr-3" color="secondary" onClick={()=>{
                  

         //  //console.log("cevalamaya a tıkladı")
            selectedReplyMessage(selectedMessage);
            }}>
         <IntlMessages id="button.reply"/></Button>
      </div>

      <SweetAlert show={success} 
        success title={<IntlMessages id="webMessages.newMessageModal.successMessage"/>} 
        confirmBtnText={<IntlMessages id="button.ok"/>}
                    onConfirm={()=>{
                  
                      setSuccess(false)
                     onClose()
                     
                    }
                      }>
                     
             </SweetAlert>

    </Modal>
  );
}

const mapStateToProps = ({settings,auth,messages}) => {
  const {width} = settings;
  const {authUser} = auth;
  return {width,authUser , messages}
};

export default connect(mapStateToProps,{updateMessages})(ReadMessageModal);

