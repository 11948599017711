import {
  ADD_NEW_NOTIFICATION,
  FETCH_ALL_NOTIFICATION, FETCH_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION,

  SET_ALL_READ_NOTIFICATION,



  SET_READ_NOTIFICATION, SHOW_MESSAGE, UPDATE_NOTIFICATION
} from "../constants/ActionTypes";

export const fetchNotifications = (userId) => {
  return {
    type: FETCH_ALL_NOTIFICATION,
    payload: userId,
  };
};



export const fetchNotificationsSuccess = (mails) => {
  return {
    type: FETCH_ALL_NOTIFICATION_SUCCESS,
    payload: mails,
  };
};
export const setReadNotification = (notification) => {
    return {
      type: SET_READ_NOTIFICATION,
      payload: notification,
    };
  };
  export const setAllReadNotification = () => {
      return {
        type: SET_ALL_READ_NOTIFICATION
      };
    };
export const showNotificationsMessage = (message) => {
//  console.log("showNotificationsMessage ", message); 
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const updateNotifications = (searchNotifications) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: searchNotifications,
  };
};

export const getAllNotifications = () => {
  return {
    type: GET_ALL_NOTIFICATION
   
  };
};

export const addNewNotification = (notification) => {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: notification,
  };
};