
import axios from 'axios';
import config from '../../config';
import { getAxiosConfigUserToken } from '../../util/utilFunction';


  
export const postSendSMS = (smsObject)=>{
   const url = `${config.apiUrl}/v1/util/sendSMS`;
   return axios.post(url,smsObject,getAxiosConfigUserToken())
   .then(response => response.data);
}

export const getBanksApi = async()=>{
   const url = `${config.apiUrl}/v1/util/banks`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
 
export const getInterestApi = async()=>{
    const url = `${config.apiUrl}/v1/util/interests`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getDepartmentsApi = async()=>{
    const url = `${config.apiUrl}/v1/util/departments`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getLanguagesApi = async()=>{
    const url = `${config.apiUrl}/v1/util/languages`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getSettingsApi = async()=>{
   const url = `${config.apiUrl}/v1/util/settings`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
 export const postUploadPubFileApi = async(formData,onUploadProgress)=>{
    //  console.log("file upload  çalıştı");
              try{
              const url = `${config.apiUrl}/v1/upload/pubFile/`; 
              return await axios.post(url,formData,{
                      
                          headers:{
                              'Content-Type':'multipart/form-data',
                              ...getAxiosConfigUserToken().headers,
                          },
                          
                          onUploadProgress
                  }).then(response => response.data) 
  
              } catch (err) {
                  if(err.response.status ===500){
                      console.log("Sunucu problemi var")
                      return {status:false , errorMessage:"Sunucu problemi var" }
                  }
                  else{
                      console.log(err.response.data.msg);
                      return {status:false , errorMessage:err.response.data.msg }
                  }
              }
  }
  export const deletePubFileApi = (file)=>{
    const url = `${config.apiUrl}/v1/upload/deletePubFile`;
    return axios.post(url,file,getAxiosConfigUserToken())
    .then(response => response.data);
 }


  
 export const postSaveFileAttachmentList = (fileAtttachementObject)=>{
    const url = `${config.apiUrl}/v1/fileAttachment/fileAttachmentList`;
    return axios.post(url,fileAtttachementObject,getAxiosConfigUserToken())
    .then(response => response.data);
 }

 export const getFileAttachmentByPubApi = async(pubId)=>{
    const url = `${config.apiUrl}/v1/fileAttachment/byPubId/${pubId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const deleteFileAttachmentByPubApi = async(pubId)=>{
   const url = `${config.apiUrl}/v1/fileAttachment/byPubId/${pubId}`;
   return await axios.delete(url,getAxiosConfigUserToken())
   .then(response => response.data)
}

 export const getAssigmentEditorPubListPDFByInstApi = (inst,lang)=>{
    const url = `${config.apiUrl}/v1/pdf/pubAssigned/assigmentEditorPubListByInst/${inst}?lang=${lang}`;
    return axios.get(url,{
       method: 'GET',
       responseType: 'blob',
       ...getAxiosConfigUserToken()
    })
    .then(response => {
       const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          return file;
    });
 }
 export const getAssigmentEditorPubListPDFByEditorIdApi = (editorId,inst,lang)=>{
    const url = `${config.apiUrl}/v1/pdf/pubAssigned/assigmentEditorPubListByEditorId/${editorId}/${inst}?lang=${lang}`;
    return axios.get(url,{
       method: 'GET',
       responseType: 'blob',
       ...getAxiosConfigUserToken()
    })
    .then(response => {
       const file = new Blob( 
          [response.data], 
          {type: 'application/pdf'});
          return file;
    });
 }
 export const getAssigmentEditorPubListFullPDFByInstApi = (inst,lang)=>{
    const url = `${config.apiUrl}/v1/pdf/pubAssigned/assigmentEditorPubListFullByInst/${inst}?lang=${lang}`;
    return axios.get(url,{
       method: 'GET',
       responseType: 'blob',
       ...getAxiosConfigUserToken()
    })
    .then(response => {
       const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          return file;
    });
 }

 
export const getDocumentByIdApi = (fileId)=>{
   const url = `${config.apiUrl}/v1/util/document/${fileId}`;
   return axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data);
 }



export const postUploadPubReviewFileApi = async(formData)=>{
   //  console.log("file upload  çalıştı");
             try{
             const url = `${config.apiUrl}/v1/upload/pubReviewFile/`;
             return await axios.post(url,formData,{
                         headers:{
                             'Content-Type':'multipart/form-data',
                             ...getAxiosConfigUserToken().headers
                         }
                 }).then(response => response.data)
 
             } catch (err) {
                 if(err.response.status ===500){
                     console.log("Sunucu problemi var")
                     return {status:false , errorMessage:"Sunucu problemi var" }
                 }
                 else{
                     console.log(err.response.data.msg);
                     return {status:false , errorMessage:err.response.data.msg }
                 }
             }
 }

 export const deletePubReviewFileAttachmentApi = (fileAttachment)=>{
   const url = `${config.apiUrl}/v1/upload/deletePubReviewFileAttachment`;
   return axios.post(url,fileAttachment)
   .then(response => response.data);
}

export const getPubByUidApi = async(uid)=>{
   const url = `${config.apiUrl}/v1/util/pub/byUid/${uid}`;
   return await axios.get(url)
   .then(response => response.data)
}



//////taslak harcama pusulasi
export const getExpenseSlipTemplateFileByPubIdAndUserIdApi = (pubId,userId)=>{
   const url = `${config.apiUrl}/v1/word/harcamaPusulasi/${pubId}/${userId}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/octet-stream'});
         return file;
   });
}

/////taslak sozlesme formu
export const getSozlesmeByPubIdApi = (pubId)=>{
   const url = `${config.apiUrl}/v1/word/sozlesme/${pubId}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/octet-stream'});
         return file;
   });
}
/////telif Devir sozlesme formu
export const getTelifDevirSozlemesiByPubIdApi = (pubId)=>{
   const url = `${config.apiUrl}/v1/word/telifDevirSozlesmesi/${pubId}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data],  
         {type: 'application/octet-stream'});
         return file;
   });
}




//email ile yazara ve inceleyiciye sözleşme formu veya harcama pusulası göndermek için.
export const postSendEmailFileWriterAndReferee = (data)=>{
   const url = `${config.apiUrl}/v1/util/sendEmailFileWriterAndReferee/`;
   return axios.post(url,data,getAxiosConfigUserToken())
   .then(response => response.data);
}

export const getHesapVeIncelemeFormuPDFByPubApi = (pubId , signName , signTitle)=>{
   const url = `${config.apiUrl}/v1/pdf/hesapVeIncelemeFormu/${pubId}?signName=${signName}&signTitle=${signTitle}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}
export const getFullFileZipForAdvisoryBoardByPubApi = (pubId )=>{
   const url = `${config.apiUrl}/v1/pdf/fullFileZipForAdvisoryBoard/${pubId}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/zip'});
         return file;
   });
}

export const getTRDizinOdisReportPDFByPubApi = async (pubId , type)=>{
   const url = `${config.apiUrl}/v1/pdf//TRDizinMakaleOdisRaporu/${pubId}/${type}`;
   return await axios.get(url,{
      method: 'GET',
      responseType: 'blob',
      ...getAxiosConfigUserToken()
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}

/////////////////////////eski




export const getUserStatisticsByUserIdApi = async(userId)=>{
    const token = localStorage.getItem("userTokenYaysis");
    const configAxios = { 
       headers: { Authorization: "Bearer " + token }
    }

    const url = `${config.apiUrl}/v1/util/statistics/user/${userId}`;
    return await axios.get(url,configAxios)
    .then(response => response.data)
 }

export const getQuestionByTypeApi = async(type)=>{
    const url = `${config.apiUrl}/v1/question/type/${type}`;
    return await axios.get(url)
    .then(response => response.data)
 }

export const getEdsisDataByEdsisIdApi = async(edsisId)=>{
    const url = `${config.apiUrl}/v1/util/getDataEdsis/${edsisId}`;
    return await axios.get(url)
    .then(response => response.data)
 }
 export const postUploadSubmissionFileApi = async(formData)=>{
    //  console.log("file upload  çalıştı");
              try{
              const url = `${config.apiUrl}/v1/upload/submissionFile/`;
              return await axios.post(url,formData,{
                          headers:{
                              'Content-Type':'multipart/form-data'
                          }
                  }).then(response => response.data)
  
              } catch (err) {
                  if(err.response.status ===500){
                      console.log("Sunucu problemi var")
                      return {status:false , errorMessage:"Sunucu problemi var" }
                  }
                  else{
                      console.log(err.response.data.msg);
                      return {status:false , errorMessage:err.response.data.msg }
                  }
              }
  }

  export const deleteSubmissionReviewFileAttachmentApi = (fileAttachment)=>{
    const url = `${config.apiUrl}/v1/upload/deleteSubmissionReviewFileAttachment`;
    return axios.post(url,fileAttachment)
    .then(response => response.data);
 }

 export const postUploadActivityAttachment = async(formData,userId)=>{
    //  console.log("file upload  çalıştı");
              try{
              const url = `${config.apiUrl}/v1/upload/activityAttachment/${userId}`;
              return await axios.post(url,formData,{
                          headers:{
                              'Content-Type':'multipart/form-data'
                          }
                  }).then(response => response.data)
  
              } catch (err) {
                  if(err.response.status ===500){
                      console.log("Sunucu problemi var")
                      return {status:false , errorMessage:"Sunucu problemi var" }
                  }
                  else{
                      console.log(err.response.data.msg);
                      return {status:false , errorMessage:err.response.data.msg }
                  }
              }
  }

  export const getActivityDataByActivityIdApi = async(activityId)=>{
    const url = `${config.apiUrl}/v1/activityAttachment/byActivityId/${activityId}`;
    return await axios.get(url)
    .then(response => response.data)
 }


 
 export const deleteActivityAttachmentApi = (activityAttachment)=>{
    const url = `${config.apiUrl}/v1/upload/deleteActivityAttachment`;
    return axios.post(url,activityAttachment)
    .then(response => response.data);
 }

 
  export const postReminderMessage = (reminderObject)=>{
   const url = `${config.apiUrl}/v1/util/reminderMessage`;
   return axios.post(url,reminderObject)
   .then(response => response.data);
}

export const getJournallNameAllApi = async()=>{
   const url = `${config.apiUrl}/v1/util/journalName`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data) 
}
export const putUpdateJournallNameApi = async(obj)=>{
   const url = `${config.apiUrl}/v1/util/journalName`;
   return await axios.put(url,obj,getAxiosConfigUserToken())
   .then(response => response.data) 
}

export const postDigitalArchive = (data)=>{
   const url = `${config.apiUrl}/v1/util/digitalArchive/`;
   return axios.post(url,data,getAxiosConfigUserToken())
   .then(response => response.data);
}
export const getDigitalArchiveByPubIdAndISBN = (pubId,isbn)=>{
   const url = `${config.apiUrl}/v1/util/digitalArchive/${pubId}/${isbn}`;
   return axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data);
}