import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    UPDATE_USER,
    SET_USER 
} from "constants/ActionTypes";
import config from "../config";
import SimpleCrypto from "simple-crypto-js";
const simpleCrypto = new SimpleCrypto(config.sk);

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
   // userName: localStorage.getItem('userName'),
 //   authUser: localStorage.getItem("userYaysis")===null ? {name:"Ad",surname:"Soyad",profileImageUrl:"#"} :  simpleCrypto.decryptObject(localStorage.getItem("userYaysis")),
    authUser: localStorage.getItem("userYaysis")===null ? null :  simpleCrypto.decryptObject(localStorage.getItem("userYaysis")),

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
       
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
                initURL: config.signInUrl,
               // userName: action.payload.name + ' '+ action.payload.surname
            }
        }
        case INIT_URL: { 
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser:  {name:"Ad",surname:"Soyad",profileImageUrl:"#"} ,
                initURL: config.signOutUrl,
                loader: false,
            //    userName:""
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_USER: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
            //    userName: action.payload.name + ' '+ action.payload.surname,
            }
        }
        case SET_USER: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
            }
        }
        default:
            return state;
    }
}
