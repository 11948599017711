import { Chip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { getDateLocaleShort } from '../../util/utilFunction';


const NotificationItem = ({notification , setReadNotification  }) => {
  const { body, addedDate,notificationType} = notification;
  return (
    <li className="media">
      {/* <Avatar
        alt={image}
        src={image}
        className=" mr-2"
      /> */}


      <div className="media-body align-self-center">


        <p className="sub-heading mb-0">
        <Chip className="m-1" variant="outlined" size="small" color={notificationType===1 ? "secondary" : notificationType ===2 ? "primary" : "default"} component="span" label={<IntlMessages id={"notification.type."+notificationType}/>}/>

          {body}</p>
        <Button size="small" color="secondary" className="jr-btn jr-btn-xs mb-0" onClick={()=>setReadNotification(notification)}><i
          className={`zmdi zmdi-notifications-off zmdi-hc-fw`}/><IntlMessages id="button.isRead"/></Button> <span className="meta-date"><small>{getDateLocaleShort("tr",addedDate)}</small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
