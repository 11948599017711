
import axios from 'axios';
import config from '../../config';


export const getUserByInstApi = async(inst)=>{

   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.apiUrl}/v1/user/byInst/${inst}`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
 }

export const getUserByRoleIdApi = async(roleId)=>{

   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.apiUrl}/v1/user/byUserRoleId/${roleId}`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
 }

 export const getUserByRoleIdAndInstApi = async(roleId,inst)=>{

   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.apiUrl}/v1/user/byUserRoleIdAndInst/${roleId}/${inst}`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
 }
export const postCreateUserApi = async(user)=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = { 
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.userAuthApiUrl}/v1/user/newUser/otherApp`;
    return await axios.post(url,user,configAxios)
    .then(response => response.data)
 }
 export const putUpdateUserApi = async(user)=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = { 
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.userAuthApiUrl}/v1/user/`;
    return await axios.put(url,user,configAxios)
    .then(response => response.data).catch(error=>{
     console.log("error ",error.response)
        if(error.response.status === 403)
          {
            localStorage.removeItem("userTokenYaysis");
            window.location.href =
              config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
          }
    })
 }
 export const postSaveUserRoleApi = async(userRole)=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = { 
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.apiUrl}/v1/user/saveUserRole/`;
    return await axios.post(url,userRole,configAxios)
    .then(response => response.data)
 }
 export const deleteUserRoleApi = async(userRole)=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = { 
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.apiUrl}/v1/user/deleteUserRole/`;
    return await axios.post(url,userRole,configAxios)
    .then(response => response.data)
 }
export const getUserAllApi = async()=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.userAuthApiUrl}/v1/user/`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(error=>{
      console.log("error ",error.response)
         if(error.response.status === 403)
           {
             localStorage.removeItem("userTokenYaysis");
             window.location.href =
               config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
           }
     })
 }
 export const getUserPublicAllApi = async()=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.userAuthApiUrl}/v1/user/public/`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(error=>{
      console.log("error ",error.response)
         if(error.response.status === 403)
           {
             localStorage.removeItem("userTokenYaysis");
             window.location.href =
               config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
           }
     })
 }
 export const getUserByIdApi = async(id)=>{

   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

    const url = `${config.userAuthApiUrl}/v1/user/${id}`;
    return await axios.get(url,configAxios)
    .then(response => response.data).catch(error=>{
      console.log("error ",error.response)
         if(error.response.status === 403)
           {
             localStorage.removeItem("userTokenYaysis");
             window.location.href =
               config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
           }
     })
 }

 export const postUploadUserProfileImage = (formData,userId)=>{
   //  console.log("user profile image upload çalıştı");
         
             
   const token = localStorage.getItem("userTokenYaysis");


             const url = `${config.userAuthApiUrl}/v1/upload/userProfileImage/${userId}`;
             return  axios.post(url,formData,{
                         headers:{
                             'Content-Type':'multipart/form-data',
                             Authorization: "Bearer " + token
                         }
                 }).then(response => response.data);
 
 }
