import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "assets/vendors/style";
import AppLocale from "../lngProvider";
import { switchLanguage } from "actions/Setting";
import MainApp from "app/index";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { postUserOtherAuthCheck } from "../api/auth";
import config from "../config";
import {
  userSignIn,
  userUpdate,
  userSignInSuccess,
  setUser,
} from "actions/Auth";
import {
  fetchMessages
} 
from "actions/Messages"
import languageData from "../components/LanguageSwitcher/data";
import { IntlProvider } from "react-intl";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from "constants/ThemeColors";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import darkTheme from "./themes/darkTheme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import trLocale from "date-fns/locale/tr";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import Pub from "./Pub";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import PubReviewCorrect from "./PubReviewCorrect";

class App extends Component {
  componentDidMount = async () => {
    const paramsString = this.props.location.search;
    // const paramsString = "?userId=4203&pwd=e10adc3949ba59abbe56e057f20f883e&first=1&userRole=22&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDIwMywiaWF0IjoxNTk5NDYzNDA3LCJleHAiOjE1OTk0NzQyMDd9.zsfNjBzIZbXSJIHHiFw2eBgio7ZOiMNU57lrJvDDZn4&lng=tr"
    const params = new URLSearchParams(paramsString);
    // const userId = params.get("userId");
    // const pwd = params.get("pwd");
    // const token = params.get("token");
    // const language = params.get("lng");
    // const url = params.get("url");
    console.log("first ",params.get("t"))

    const token = params.get("t") !== null ? params.get("t").split("?")[0] : null;
    const language = params.get("t") !== null ?params.get("t").split("?")[2] : null ;


    //console.log("localedeki token ",localStorage.getItem("userTokenYaysis"))

    const pathname = this.props.location.pathname;

   //console.log("this.props pathName",pathname,this.props)

    if(pathname.includes("showPub") || pathname.includes("BelgeDogrulama"))
    {
     //console.log("kullanıcı kontrolü yapılmadan devam");
    }
    else
    {
      if (token === null) {
        if (
          localStorage.getItem("userTokenYaysis") === null || localStorage.getItem("userTokenYaysis") === "null"
        ) {

          localStorage.removeItem("userTokenYaysis"); 
          window.location.href = config.mainPageUrl + `?status=false&message=Yetkisiz erişim`;
        }
      }
      console.log('token ', token )
  
      const data = { token};
  
      if (   localStorage.getItem("userTokenYaysis") === null ||     localStorage.getItem("userTokenYaysis") === "null" || !(localStorage.getItem("userTokenYaysis") !== token && token === null && localStorage.getItem("userTokenYaysis") !== null ) //Buraya sonra bakacağız
 ) 
      {
     //   console.log(" localStorage. ", localStorage.getItem("userTokenYaysis") , token)
      //  console.log("buaraya girdi çünkü ", localStorage.getItem("userTokenYaysis") === null ,  localStorage.getItem("userTokenYaysis") === "null" ,!(localStorage.getItem("userTokenYaysis") !== token && token === null && localStorage.getItem("userTokenYaysis") !== null)  )

        //burası dil ile ilgili.
        if (language !== "" && (language !== null) & (language !== undefined)) {
          this.props.switchLanguage(
            languageData.find((ld) => ld.locale === language)
          );
        } else { 
          this.props.switchLanguage({
            languageId: "turkish",
            locale: "tr",
            name: "Türkçe",
            icon: "tr",
          });
        }


        localStorage.setItem("userTokenYaysis", token);
  
        const result = await postUserOtherAuthCheck(data).catch((error) => {
          return error.response.status === 403
            ? {
                status: false,
                message:
                  this.props.locale.locale === "tr"
                    ? "Hata Oluştu. Oturum sona erdi."
                    : "Error oncurred. Session failed",
              }
            : { status: false, message: "Error :" + error };
          //return null;
        });
  
    //   console.log("******************************************* result", result);
        if (result.status) {
          const { user } = result;
  
          if(user.id>0)
          this.props.fetchMessages(user.id);
       
          await this.props.userSignIn({ user, token });
          localStorage.setItem("userTokenYaysis", token);


         
        } 
        else 
        {
          localStorage.removeItem("userTokenYaysis");
          const { message } = result;
          console.log("message ",message)
         window.location.href =  config.mainPageUrl + `?status=false&message=${message}`;
        }
      } else {
        return <Redirect to={"/yts/mainPage"} />;
      }
  
    }

   
   //console.log("burası neden çalıştır appp didmount");

    ////console.log("gdata didmount 2 ", url );
  };

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(blueTheme);
    }
    return applyTheme;
  }
  // componentWillUnmount(){
  //   clearInterval(this.timer);
  //   this.timer = null;
  // }
  //  componentWillMount() {
  //     this.refreshMessage();
  //   }
  render() {
   //console.log("app render oldu ");
    const {
      match,
      location,
      locale,
      isDirectionRTL,
      isDarkTheme,
      themeColor,
      showMessage,
      alertMessage,
    } = this.props;

    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const activityId = params.get("aid");

    const localeMap = {
      en: enLocale,
      tr: trLocale,
      ru: ruLocale,
    };

    let applyTheme = createMuiTheme();
    if (isDarkTheme) {
      document.body.classList.add("dark-theme");
      applyTheme = createMuiTheme(darkTheme);
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }

    if (location.pathname === "/" && this.props.user !== null) {
      return <Redirect to={"/yts/mainPage"} />;
    } else if (
      location.pathname === "/yaysis/sendSubmission" &&
      this.props.user !== null
    ) {
      return <Redirect to={`/yts/addSubmissionPage/${activityId}`} />;
    }

    //   const applyTheme = createMuiTheme({
    //   typography: {
    //     useNextVariants: true,
    //   },
    // });
    //  const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[locale.locale]}
        >
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path={`${match.url}yts`} component={MainApp} />
                  <Route path={`/showPub/:uid`} component={Pub} />
                  <Route
                    path={`/inceleyiciBelgeDogrulama`}
                    component={PubReviewCorrect}
                  />

                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }, state) => {
  // //console.log("appp js state", state);
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL,
  } = settings;

  const user = state.authUser;
  const { alertMessage, showMessage, authUser } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    user,
    showMessage,
    alertMessage,
    authUser,
  };
};

export default connect(mapStateToProps, {
  userUpdate,
  userSignIn,
  userSignInSuccess,
  setUser,
  switchLanguage,
  fetchMessages
})(App);
