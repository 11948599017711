import {
  FETCH_ALL_MESSAGES,
  FETCH_ALL_MESSAGES_SUCCESS,
  HANDLE_REQUEST_CLOSE,
  ON_HIDE_LOADER,
  ON_SORTEND,
  SHOW_MESSAGE,
  UPDATE_SEARCH,
  UPDATE_MESSAGES,
  SET_INBOX_MESSAGES,
  SET_SENDBOX_MESSAGES
} from "../constants/ActionTypes";

export const fetchMessages = (userId) => {
  return {
    type: FETCH_ALL_MESSAGES,
    payload: userId,
  };
};

export const fetchMessagessSuccess = (mails) => {
  return {
    type: FETCH_ALL_MESSAGES_SUCCESS,
    payload: mails,
  };
};

export const showMessagesMessage = (message) => {
//  console.log("showMessagesMessage ", message);
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const updateMessages = (searchMessages) => {
  return {
    type: UPDATE_MESSAGES,
    payload: searchMessages,
  };
};
export const setInboxMessages = (inboxMessages , message) => {
  return {
    type: SET_INBOX_MESSAGES,
    payload: {inboxMessages , message},
  };
};
export const setSendboxMessages = (sendboxMessages , message) => {
  return {
    type: SET_SENDBOX_MESSAGES,
    payload: {sendboxMessages , message},
  };
};

export const onSortEnd = (data) => {
  return {
    type: ON_SORTEND,
    payload: data,
  };
};

export const updateSearch = (searchMessages) => {
  return {
    type: UPDATE_SEARCH,
    payload: searchMessages,
  };
};

export const hideToDoLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const handleToDoMenuRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
