import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Referee from './Referee';
import Messages from './Messages';
import Notifications from './Notifications';
import Pub from './Pub';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  messages : Messages,
  referee: Referee,
  notifications: Notifications,
  pub: Pub
});
