import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { fetchNotificationsSuccess, showNotificationsMessage} from 'actions/Notifications';
import {FETCH_ALL_NOTIFICATION} from 'constants/ActionTypes';
import { getNotificationByUserIdApi } from '../api/notification';

const getNotifications = async (userId=-1) =>
 {
  //burası değişiecek şimdilik gönderilenleri çekiyor
  return  await getNotificationByUserIdApi(userId).catch(error=> error)

} 

function* fetchNotificationsRequest({payload}) {
  try {
    //console.log("geet notiiiii payload ",payload)
    const userId = payload;
    const fetchedTodo = yield call(getNotifications,userId);

    //console.log("fetched notiiiii ", fetchedTodo)
    if(fetchedTodo.status)
    yield put(fetchNotificationsSuccess(fetchedTodo.data));
    else
    yield put(showNotificationsMessage(fetchedTodo.message));
  } catch (error) {
    yield put(showNotificationsMessage(error)); 
  }
}



export function* fetchNotifications(userId) {
  yield takeEvery(FETCH_ALL_NOTIFICATION, fetchNotificationsRequest);
}

export default function* rootSaga() {
  yield all([ fork(fetchNotifications)]);
}