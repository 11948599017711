import React from 'react'
import config from '../../../../config'
import { getFullName } from '../../../../util/utilFunction'

export default function UserCell({user,title,isAuthorized}) {

   // console.log("title ", title , user)
    const {profileImageUrl , name , inst ,email , tel } = user;
    return (
       
      <div className="contact-item">
         
{title !== "" ? <h4 className="font-weight-bold col-2">
    {title}
</h4> : null}
     
      {(profileImageUrl === null || profileImageUrl === '') ?
        <div className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
             style={{fontSize: 20}}>
          {name.charAt(0).toUpperCase()}
        </div> :
        <img className="rounded-circle size-40 mx-1 mx-md-3" alt={name} src={config.userAuthApiUrl +  profileImageUrl} onError={(e)=>{e.target.onerror = null; e.target.src=config.userAuthApiUrl + config.defaultProfileImageUrl }} />}

      <div className="col con-inf-mw-100">
        <p className="mb-0">
                      <span className="text-truncate contact-name text-dark">
                          {getFullName(user)}
                      </span>
          <span className="d-inline-block toolbar-separator">&nbsp;</span>
          <span className="text-truncate job-title text-dark">
                          {inst}
                      </span>
        </p>

        <div className="text-muted">
                      <span className="email d-inline-block mr-2">
                          {email}
                      </span>
                   {   isAuthorized &&
          <span className="phone d-inline-block">
                          {tel}
                      </span>}
        </div>
      </div>


    </div>

    )
}
