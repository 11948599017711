import React from 'react';

import config from '../../config';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">{config.copright} &copy; {config.coprightYear}</span>
      </footer>
    );
  }
;

export default Footer;
