import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import messagesSagas from './Messages';
import notiSagas from './Notifications';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        messagesSagas(),
        notiSagas()
    ]);
}
