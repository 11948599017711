import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import config from '../../config';
import { history } from '../../store';

// axios.interceptors.request.use(function (config) {
//    const token = localStorage.getItem("userTokenYaysis");
//    config.headers.Authorization =  token;

//    return config;
// });
 

axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 403) {
   console.log(" ****** ana hata yakalamayaya girdi 403");
   NotificationManager.error('Oturmun süresi doldu yeniden giriş yapmanız gerekiyor.' )    
  // console.log("token ",localStorage.getItem("userTokenYaysis"))
   localStorage.removeItem("userYaysis");
   localStorage.removeItem("userTokenYaysis");
   window.location.href =  config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
   history.push("/yts/403");
}
else if (error.response.status === 401) {
   console.log(" ****** ana hata yakalamayaya girdi 401");
   NotificationManager.error('Yetkisiz erişim' )     
   history.push("/yts/401");
   return {status:false , data: null , messages : "Yetkisiz erişim"}
}
else
{
   history.push("/yts/404");
}

  return error;
});



export const postUserOtherAuthCheck = async(user)=>{
   const token = localStorage.getItem("userTokenYaysis");
      const configAxios = {
         headers: { Authorization: "Bearer " + token }
      }

   const url = `${config.userCheckApiUrl}`;
   return await axios.post(url,user,configAxios)
   .then(response => response.data);
}
 

export const postCreateUserApi = async(user)=>{
   const token = localStorage.getItem("userTokenYaysis");
   const configAxios = {
      headers: { Authorization: "Bearer " + token }
   }

   const url = `${config.apiUrl}/v1/auth/signUp`;
   return await axios.post(url,user,configAxios)
   .then(response => response.data)
}
 