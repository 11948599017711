import React from 'react';
import { connect } from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import { history } from '../../store';
import NotificationItem from './NotificationItem';


const MailNotification = ({messages,fetchMessages,onMailNotificationSelect,selectedReadMessage}) => {


  const selectedReplyMessage = (selectedMessage)=>{
   //console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
   //console.log("selectedMessage tılandı ",selectedMessage);
   onMailNotificationSelect();
    history.push(`/yts/webMessagesPanelPage/${selectedMessage.id}/${selectedMessage.questionId}`)
  }
  return (
    <CustomScrollbars className="messages-list scrollbar" style={{height: 500}}>
      <ul className="list-unstyled">
        {
        messages.inboxMessages.map((notification, index) => <NotificationItem key={index}
           notification={notification} selectedReadMessage={selectedReadMessage} selectedReplyMessage={selectedReplyMessage}/>)
           }
      </ul>

   



    </CustomScrollbars>
  )
}; 
//export default SimpleDialog;
const mapStateToProps = ({auth,settings,messages}) => {
  const {authUser} = auth;
  const {locale} = settings;
  return { authUser ,locale,messages};
};

export default connect( mapStateToProps)(MailNotification);


