
import { SET_PUB_REVIEW_REFERE } from '../constants/ActionTypes';



export const setPubReviewReferee = (pub,pubReview, isReview,operating) => {
    return {
        type: SET_PUB_REVIEW_REFERE,
        payload: {pub, pubReview, isReview,operating}
    };
};
