
import {
  FETCH_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION,
  GET_NOREAD_NOTIFICATION,
  GET_READ_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SET_READ_NOTIFICATION,
  SET_ALL_READ_NOTIFICATION
} from "constants/ActionTypes";
import { ADD_NEW_NOTIFICATION } from "../constants/ActionTypes";

const INIT_STATE = {
    newNotificationSize : null ,
  alertMessage: "",
  loader: true,
  notifications: [],
  readNotifications:[],
  noReadNotifications:[],
 
};

export default (state = INIT_STATE, action) => {
  //console.log("action ve state notifisation ",action , state)


  switch (action.type) {
   
    case GET_ALL_NOTIFICATION: {
      return { 
        ...state,
        notifications: state.notifications,
      };
    }
    
    case GET_READ_NOTIFICATION: {
      let readNotifications = state.notifications.filter((n) => n.status === 1 );
      return {
        ...state,
        readNotifications,
        newNotificationSize : readNotifications.length
      };
    }
      
    case SET_READ_NOTIFICATION: {
      let notification = action.payload;
     //console.log("notification ",notification)
      let readNotifications = state.readNotifications;
      notification.status= 1;
      readNotifications.push(notification)

      let noReadNotifications = state.noReadNotifications.filter(x=> x.id !== notification.id);
      return {
        ...state,
        readNotifications,
        noReadNotifications,
        newNotificationSize : noReadNotifications.length
      };
    }

    case ADD_NEW_NOTIFICATION: {
      let notification = action.payload;
     //console.log("new notification ",notification)
      let noReadNotifications = state.noReadNotifications;
      noReadNotifications.unshift(notification)

      return {
        ...state,
        noReadNotifications,
        newNotificationSize : noReadNotifications.length
      };
    }

    case GET_NOREAD_NOTIFICATION: {
      let noReadNotifications = state.notifications.filter((n) => n.status === 0 );
      return {
        ...state,
        noReadNotifications,
       
      };
    }


    case FETCH_ALL_NOTIFICATION_SUCCESS: {
   // //console.log("action.payload notificationl ", state,action.payload);
     
      let notifications = action.payload;
      let readNotifications = notifications.filter(x=>x.status===1);
      let noReadNotifications = notifications.filter(x=>x.status===0);
      let newNotificationSize = noReadNotifications.length;

      return {
        ...state,
        newNotificationSize ,      
        notifications,
        readNotifications,
        noReadNotifications,
     
      };
    }
    case SET_ALL_READ_NOTIFICATION:{
      return {
        ...state,
        newNotificationSize:0,
        notifications: state.notifications.map(x=>{x.status = 1 ; return x ; }),
        readNotifications:state.notifications.map(x=> {x.status = 1 ; return x ; }),
        noReadNotifications:[],
      };
    }
    case UPDATE_NOTIFICATION:{
      return {
        ...state,
        ...action.payload,
      };
    }
    
 

    default:
      return state;
  }
};
