import { Chip, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from 'util/IntlMessages';
import config from '../../config';
import { isRoleCheckList } from '../../util/utilFunction';



class SidenavContent extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');

    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open')
          }
        }
        this.classList.toggle('open');
      }
    }


    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }


  }

  componentWillReceiveProps(nextProps) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element

   // console.log("activeLi ",activeLi)
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] == 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
 
    } 

    return null;
  }

  isRoleCheck = (roles,roleId)=>{
    return roles.find(x=> x.id===roleId) !== undefined
  }

  render() {
    const userRoles = this.props.authUser !== null ? this.props.authUser.userRoles : [];
    const coordinatorMenuStatus = this.props.authUser !== null ? isRoleCheckList(userRoles,[1,51,52,53,54]) : true ;
 
 const writerStatus = this.props.authUser !== null ?  isRoleCheckList(userRoles,[54,55,52,53,57]) : true ;
 // console.log("userRoles ",userRoles,writerStatus)
    return (
      <CustomScrollbars className="scrollbar">


        <ul className="nav-menu">
    
    
          <li className="nav-header">
          <IntlMessages id="sidebar.main"/> 
          </li>
          <li className="menu no-arrow">
            <NavLink to="/yts/mainPage">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.mainPage"/></span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/yts/mainPage">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text"> 
              Eski Versiyona Geçiş
              </span>
            </NavLink>
          </li> */}
          <li className="menu no-arrow">
            <NavLink to="/yts/publicationPrinciplesPage">
            <i className="zmdi zmdi-device-hub zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.publicationPrinciples"/></span>
            </NavLink>
          </li>
         { config.educationVideoVisible && <li className="menu no-arrow">
            <NavLink to="/yts/educationPage">
            <i className="zmdi zmdi-comment-video zmdi-hc-fw"/>

              <span className="nav-text"> <IntlMessages id="sidebar.education"/></span>
            </NavLink>
          </li>}
          {isRoleCheckList(userRoles,[1,51,52,53,54,55,56,58]) &&
          <li className="menu no-arrow">
            <NavLink to="/yts/searchUserProfile">
              <i className="zmdi zmdi-search zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="absis.userSearch"/></span>
            </NavLink>
          </li>}   
          <li className="menu no-arrow">
            <NavLink to="/yts/faq">
            <i className="zmdi zmdi-pin-help zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.faq"/></span>
            </NavLink>
          </li>
          {isRoleCheckList(userRoles,[1,51,52,53,54,55]) &&
          <li className="menu no-arrow">
            <NavLink to="/yts/searchPub">
              <i className="zmdi zmdi-search-in-file zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.searchPub"/></span>
            </NavLink>
          </li>}

                  {isRoleCheckList(userRoles,[1,53,51,52]) &&
                  <li className="menu no-arrow">
            <NavLink to="/yts/statisticsPage">
              <i className="zmdi zmdi-chart zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.statisticsPage"/></span>
            </NavLink>
          </li>}
          {isRoleCheckList(userRoles,[1,53,51,52]) &&
                  <li className="menu no-arrow">
            <NavLink to="/yts/messageManagementPage">
              <i className="zmdi zmdi-email zmdi-hc-fw"/>
              <span className="nav-text"> Mesaj Yönetimi</span>
            </NavLink>
          </li>}

 

          { writerStatus && 
  <li className="menu collapse-box" id="writerMenu"> 
  <Button>
      <i className="zmdi zmdi-book zmdi-hc-fw"/>
      <span className="nav-text">
     <IntlMessages id="sidebar.wirterRefereeProcess.ex"/>  
      
      </span>
    </Button>
    <ul className="sub-menu" id="writerSubMenu">
             <li>
            <NavLink to="/yts/myProfile">
              <i className="zmdi zmdi-account-calendar zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="absis.myProfile"/></span>
            </NavLink>
          </li> 
          <li>
            <NavLink to="/yts/sendArticlePage">
              <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.sendArticle"/></span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/yts/sendBookPage">
              <i className="zmdi zmdi-book zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.sendBook"/></span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/yts/myArticlePage">
              <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myArticles"/></span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/yts/myBooksPage">
              <i className="zmdi zmdi-book zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myBooks"/></span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/yts/myRefereePage">
              <i className="zmdi zmdi-rss zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myReferee"/></span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/yts/myEditorialWorkPage">
              <i className="zmdi zmdi-edit zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myEditorialWork"/></span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/yts/myTranslatePage">
              <i className="zmdi zmdi-translate zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myTranslate"/></span>
            </NavLink>
          </li>
      </ul>
      </li>
  }

          {isRoleCheckList(userRoles,[1,51,58,56]) ? [
     
<li className="nav-header" key="hg1">
          Harcama Görevlisi
          </li>,
                <li className="menu no-arrow" key="hg2">
                <NavLink to="/yts/pubPrintingPage">
                  <i className="zmdi zmdi-print zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.pubPrinting"/></span>
                  </NavLink>
                </li>,
                <li className="menu no-arrow" key="hg3">
                  <NavLink to="/yts/bookPrintingPage">
                    <i className="zmdi zmdi-account zmdi-hc-fw" />
                    <span className="nav-text">
                   Kitap Basım İşleri
                    </span>
                  </NavLink>
                </li>,
                <li className="menu no-arrow" key="hg4">
                  <NavLink to="/yts/journalPrintingPage">
                    <i className="zmdi zmdi-account zmdi-hc-fw" />
                    <span className="nav-text">
                   Dergi Basım İşleri
                    </span>
                  </NavLink>
                </li>,
                <li className="menu no-arrow" key="hg5">
                  <NavLink to="/yts/articlePrintingPage">
                    <i className="zmdi zmdi-account zmdi-hc-fw" />
                    <span className="nav-text">
                   Makale Telif İşleri
                    </span>
                  </NavLink>
                </li>
                ]:null}
            
   
      
{ 
  isRoleCheckList(userRoles,[54,55,52,53,57]) ?  null :
          [<li className="nav-header" key="wrp1">
          <IntlMessages id="sidebar.wirterRefereeProcess"/> 
          </li>,
              <li className="menu no-arrow" key="wrp2">
              <NavLink to="/yts/myProfile">
                <i className="zmdi zmdi-account-calendar zmdi-hc-fw"/>
                <span className="nav-text"> <IntlMessages id="absis.myProfile"/></span>
              </NavLink>
            </li>,         
            <li className="menu no-arrow"  key="wrp3">
              <NavLink to="/yts/sendArticlePage">
                <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
                <span className="nav-text"> <IntlMessages id="sidebar.sendArticle"/></span>
              </NavLink>
            </li>,
            <li className="menu no-arrow" key="wrp4">
              <NavLink to="/yts/sendBookPage">
                <i className="zmdi zmdi-book zmdi-hc-fw"/>
                <span className="nav-text"> <IntlMessages id="sidebar.sendBook"/></span>
              </NavLink>
            </li>,
          <li className="menu no-arrow"  key="wrp1">
            <NavLink to="/yts/myArticlePage" key="wrp5">
              <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myArticles"/></span>
            </NavLink>
          </li>,


          <li className="menu no-arrow" key="wrp6">
            <NavLink to="/yts/myBooksPage">
              <i className="zmdi zmdi-book zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myBooks"/></span>
            </NavLink>
          </li>,
          <li className="menu no-arrow" key="wrp7">
            <NavLink to="/yts/myRefereePage">
              <i className="zmdi zmdi-rss zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.myReferee"/></span>
            </NavLink>
          </li>,
        <li className="menu no-arrow" key="wrp6">
        <NavLink to="/yts/myEditorialWorkPage">
          <i className="zmdi zmdi-edit zmdi-hc-fw"/>
          <span className="nav-text"> <IntlMessages id="sidebar.myEditorialWork"/></span>
        </NavLink>
      </li>,
      <li className="menu no-arrow" key="wrp7">
        <NavLink to="/yts/myTranslatePage">
          <i className="zmdi zmdi-translate zmdi-hc-fw"/>
          <span className="nav-text"> <IntlMessages id="sidebar.myTranslate"/></span>
        </NavLink>
      </li>  
        ]
      
} 

{
  isRoleCheckList(userRoles,[54,55,52,53,57]) &&
[  <li className="nav-header" key="c1dsds">
          <IntlMessages id="sidebar.coordinatorAndEditor"/> 
          </li>,
             
             this.isRoleCheck(userRoles,55) && <li className="menu no-arrow "  key="e3">
             <NavLink to="/yts/inProgressPage">
               <i className="zmdi zmdi-spinner zmdi-hc-fw"/>
               <span className="nav-text"> <IntlMessages id="sidebar.inProgress"/></span>
             </NavLink>
           </li>,
            <li className="menu no-arrow "  key="e4">
            <NavLink to="/yts/inInstitutions">
              <i className="zmdi zmdi-home zmdi-hc-fw"/>
              <span className="nav-text"> <IntlMessages id="sidebar.inInstitutions"/></span>
            </NavLink>
          </li>,
          <li className="menu no-arrow" key="e6">
    <NavLink to="/yts/royaltyCalculationPage">
      <i className="zmdi zmdi-money zmdi-hc-fw"/>
      <span className="nav-text"><IntlMessages id="sidebar.royaltyCalculationPage"/> </span>
    </NavLink>
  </li> ,
  <li  className="menu no-arrow" key="e7">
  <NavLink to="/yts/facsimile">
    <i className="zmdi zmdi-copy zmdi-hc-fw"/>
    <span className="nav-text"> Tekrar Baskı ve Kurum İçi</span>
  </NavLink>
</li>
//linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
,
  isRoleCheckList(userRoles,[52,53,54,55]) && <li >
  <NavLink to="/yts/journalManagementPage">
    <i className="zmdi zmdi-group zmdi-hc-fw"/>
    <span className="nav-text"><IntlMessages id="sidebar.journalManagement"/> </span>
  </NavLink>
</li>

        ]
}
{ coordinatorMenuStatus && 
  <li className="menu collapse-box" id="coordinatorMenu"> 
  <Button>
      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
      <span className="nav-text">
        <IntlMessages id="sidebar.coordinator"/>
        
      </span>
    </Button>
    <ul className="sub-menu" id="coordinatorSubMenu">
   { this.isRoleCheck(userRoles,54) && <li  key="c2">
             <NavLink to="/yts/assignedPubPage">
               <i className="zmdi zmdi-assignment-account zmdi-hc-fw"/>
               <span className="nav-text"> <IntlMessages id="sidebar.assignedPub"/></span>
             </NavLink>
           </li>}
           {  isRoleCheckList(userRoles,[53,54,52]) &&  <li >
  <NavLink to="/yts/roleManagementPage">
      <i className="zmdi zmdi-account zmdi-hc-fw"/>
      <span className="nav-text"><IntlMessages id="sidebar.roleManagement"/> </span>
    </NavLink>
  </li> }

  { this.isRoleCheck(userRoles,54) && <li>
    <NavLink to="/yts/publicationCommisionPage">
      <i className="zmdi zmdi-settings zmdi-hc-fw"/>
      <span className="nav-text"><IntlMessages id="sidebar.publicationCommision"/> </span>
    </NavLink>
  </li>}
  { this.isRoleCheck(userRoles,54) && <li>
    <NavLink to="/yts/publicationBoardPage">
      <i className="zmdi zmdi-settings zmdi-hc-fw"/>
      <span className="nav-text"><IntlMessages id="sidebar.publicationBoard"/> </span>
    </NavLink>
  </li>}
  { this.isRoleCheck(userRoles,54) &&   <li>
      <NavLink to="/yts/beforeAssignedPubPage">
        <i className="zmdi zmdi-assignment-check zmdi-hc-fw"/>
        <span className="nav-text"> <IntlMessages id="sidebar.beforeAssignedPub"/></span>
      </NavLink>
    </li>}
    
    
  { this.isRoleCheck(userRoles,54) &&   <li>
      <NavLink to="/yts/settings">
        <i className="zmdi zmdi-settings zmdi-hc-fw"/>
        <span className="nav-text"> Ayarlar</span>
      </NavLink>
    </li>}
    </ul>
  </li>  }  



        </ul>
      </CustomScrollbars>
    );
  }
}


const mapStateToProps = ({auth,settings}) => {
  const {authUser} = auth;
  const {locale} = settings;
  return {authUser,locale};
};

export default withRouter(connect(mapStateToProps)(SidenavContent));


