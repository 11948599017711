import {filterXSS} from 'xss'
import config from '../config'
import CryptoJS  from'crypto-js';


export function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}
 
export function calculateRoyaltyGross(mainSectionsAndIndicators,salaryCoefficient){

  const tmainSectionsAndIndicators= mainSectionsAndIndicators.split(",").map(item=>{
        
    return {
      bolum : config.telifGostergeler.find(x=>x.index === Number(item.split("-")[0])).alt.find(y=>y.baslik===item.split("-")[1][0]).detay,
      gosterge: item.split("*")[0],
      tutar :  Number(item.split(/[()]/)[1]) * ( Number(item.split("*")[1])/200) * salaryCoefficient , 
      wordSize: Number(item.split("*")[1])
    }
  })
  return tmainSectionsAndIndicators.map(({ tutar }) => tutar).reduce((sum, i) => sum + i, 0);

}

export function stringSplitAndEmptyArray(str,seperator){

  //   console.log("user ",user)
      return str === "" ? []: str.split(seperator)
    } 
 
export function getOtherAddWriterDetail(pub){
  //   console.log("user ",user)
      
      return   (pub.writers + (pub.other === "" ? "" : " , "+pub.other) )  
    } 

export const isRoleCheckList = (userRoles , roleList)=>{
 ////console.log("userRoles , roleList ",userRoles , roleList)
  return roleList.some(roleId=>userRoles.some(x=>x.id=== roleId))
}
export const isRoleCheck = (roles,roleId)=>{
  return roles.find(x=> x.id===roleId) !== undefined
}

export const getUploadFileFullPathDownload = (file , fileName)=>{
  //return config.apiUrl +"/uploads" + file.path + file.docId + file.ext;

  console.log("file name ",fileName)
  let a = document.createElement('a');
  a.href = config.apiUrl +"/uploads"+ file.path  +  file.docId + file.ext;
  a.download =  fileName !== undefined ? fileName : file.originalName  //  <IntlMessages id={"file.doctype."+file.docType} />;
  a.target="_blank";

  
  a.click();

}

export const getTokenIntihalNet = (id)=>{
  return CryptoJS.MD5(config.intihalNet.secretKey+id).toString()
}

export const getBase64ImageFromURL = (url) =>{
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
  
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
  
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
  
      var dataURL = canvas.toDataURL("image/png");
  
      resolve(dataURL);
    };
  
    img.onerror = error => {
      reject(error);
    };
  
    img.src = url;
  });}

export function toDate(dateStr) {
  //21.04.1990
  var parts = dateStr.split(".")
  var newDate =  new Date(parts[2], parts[1] - 1, parts[0])
 ////console.log("new date ", newDate.toLocaleDateString() );
  return newDate;
}

export function filterXSSHtmlTag(object){


     
  Object.entries(object).forEach(([key,value])=>{
      ////console.log(`${key}:${value}`)

      if(typeof value == "string" )
      object[key] =   filterXSS(value, {
          whiteList: [], // empty, means filter out all tags
          stripIgnoreTag: true, // filter out all HTML not in the whilelist
          stripIgnoreTagBody: ["script"] // the script tag is a special case, we need
          // to filter out its content
        });
   })


    return object;

}

export const getAxiosConfigUserToken = ()=>{
  const token = localStorage.getItem("userTokenYaysis");
  const configAxios = { 
     headers: { Authorization: "Bearer " + token }
  }
  return configAxios;
}
  
export function getDateMaskYYYYmmDD(date){
  date=new Date(date);
 return  date.getFullYear() + "-" +date.toLocaleString("tr", { month: "2-digit" }) + "-"+ date.toLocaleString("tr", { day: "2-digit" })  ;  
 }


export const convertHtmlToString =  (htmlValue)=>{
  let result = ""
  try {
      result = htmlValue.replace(/<\/?[^>]+(>|$)/g, "");
      result =  result.replace(/&.*;/g,'');
  } catch (error) {
   //console.log("error ",error)
      result=htmlValue
  }

  return result;
}



export function countWords(str) {
  return str.trim().split(/\s+/).length;
}
export function countWords2(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter((str)=>  str !== "").length;
}

export function getDateLocale(date){
  return new Date(date).toLocaleDateString() ;
}
export function getDateLocale2(locale,date){
  return new Date(date).toLocaleDateString(locale) ;
}
export function getDateLocaleShort(locale,date){
  return new Date(date).toLocaleString(locale,{timeStyle:'short', dateStyle:'long'}) ;
}
export function getDateLocaleShortAndDateShort(locale,date){
  return new Date(date).toLocaleString(locale,{timeStyle:'short', dateStyle:'short'}) ;
}

export function getDateYYYYmmDD(date){
  return new Date(date).toISOString().slice(0,10);
}
export function getFullName(user){
// console.log("user ",user)
  return user !== undefined ? ( user.title !== "" ? (user.title +' '+ user.name +' '+ user.surname) :( user.name +' '+ user.surname) ) : "Kullanıcı bulunamadı" ;
}

export function getExtName(user){
  return  user.name.charAt(0) +'. '+ user.surname ;
}

export function camalize(str) {
  // eslint-disable-next-line
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

export function toProperCase(s){
  // eslint-disable-next-line
    return s.replace(/([^\s:\-])([^\s:\-]*)/g,function($0,$1,$2){
        return $1.toUpperCase()+$2.toLowerCase();
    });
}

export function toTurkishCharacterToEnglish(str) {
    var charMap = {
      Ç: 'C',
      Ö: 'O',
      Ş: 'S',
      İ: 'I',
      I: 'i',
      Ü: 'U',
      Ğ: 'G',
      ç: 'c',
      ö: 'o',
      ş: 's',
      ı: 'i',
      ü: 'u',
      ğ: 'g'
    };


    var str_array = str.split('');

    for (var i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }

    str = str_array.join('');

    var clearStr = str.replace(/[çöşüğı]/gi, "");
    return clearStr
}

export const funcArrayLangugeParse = (strLang)=>{

  
  if(strLang.includes(','))
    return strLang.split(',');
  else if(strLang.includes('-'))
   return strLang.split('-');
  else if(strLang.includes('/'))
   return strLang.split('/');
   else if(strLang.includes('ve'))
   return strLang.split('ve');
   else if(strLang.includes('↵'))
   return strLang.split('↵');
   else if(strLang.includes(' '))
   return strLang.split(' ');
   else
   return [strLang]
}



export const webAddressCreator=(title)=>{
console.log("title 1 ",title)
let webAddress = toTurkishCharacterToEnglish(title);
console.log("title 2 ",webAddress)
webAddress = toProperCase(webAddress);
console.log("title 3 ",webAddress)

const now = new Date();
webAddress = webAddress+ now.getFullYear();

//webAddress = config.activityPageUrl +  webAddress.split(" ").join("");
webAddress = webAddress.split(" ").join("");

console.log("title 4 ",webAddress)



return webAddress;
}

export const getTwoDateMergeLocaleShort = (locale,date1,date2)=> {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  
// //console.log("d1.getDay() ",d1.getDay())

  if(d1.getMonth()===d2.getMonth() && d1.getFullYear()===d2.getFullYear())
  {
 //   d1.setMonth(d1.getMonth()+1);
    return d1.getDay()===d2.getDay() ? d1.toLocaleDateString(locale,{dateStyle:'long'}) : d1.toLocaleString(locale, { day: "numeric" })+"-"+d2.toLocaleString(locale, { day: "numeric" })+" "+d1.toLocaleString(locale, { month: "long" })+ " "+d1.getFullYear();
  }
  else if(d1.getMonth()!==d2.getMonth())
  {
    return  d1.toLocaleDateString(locale,{dateStyle:'long'}) + " - " +  d2.toLocaleDateString(locale,{dateStyle:'long'});
  }
 

  return "Tanımzsız" ;
}


export const  groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

