import { Dialog, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationManager } from 'react-notifications';
import { Alert,  ModalHeader } from 'reactstrap';
import IntlMessages from 'util/IntlMessages';
import { getUserByRoleIdAndInstApi } from '../../../../api/user';
import { postSaveWebMessagesApi } from '../../../../api/webMessages';
import config from '../../../../config';
import { getFullName } from '../../../../util/utilFunction';
import UserCell from './UserCell';

import io from 'socket.io-client';

//const ENDPOINT ="https://testyaysis.ayk.gov.tr/api"
//config.apiUrl
const socket = io.connect(config.appUrl , { 
  transports: [
     'websocket' ,
     'polling',
    // 'flashsocket'
  ],
  withCredentials: true

})
 

export default function NewMessageModal({open, onClose, senderUser,receiverUser,newMessageType,selectedPub , isAuthorized,isInstitution}) {
  const [body,setBody] = useState("");
  const [inst,setInst] = useState("");
  const [journalId,setJournalId] = useState(0);
  const [receiverUserId,setReceiverUserId] = useState(0);
  const [receiverUserList,setReceiverUserList] = useState([]);

  const [success,setSuccess] = useState(false);
  const [  journalList , setJournallist ] = useState([])

  const [eMail,setEmail] = useState(true);
  const [sms,setSms] = useState(true);


  // useEffect(()=>{
  //   socket.on('message',({name,message})=>{

  //    //console.log("NewMessage dialog name message **** ",name,message )}
  //   )
  // })

  const sendMessage = async () =>{

    
    const newMessage = 
    newMessageType === "detail" ? 
    {
      body,
      questionId:0, 
      isComplate:0,
      inst,
      senderUserId : senderUser.id,
      receiverUserId,
      journalId,
      pubId:selectedPub !== undefined ? selectedPub.id : -1,
      eMail,
      sms,
      receiverEmail : receiverUserList.find(x=>x.id === receiverUserId).email
      
    }
    :
    {
      body,
      questionId:0,
      isComplate:0,
      inst:senderUser.inst,
      senderUserId : senderUser.id,
      receiverUserId : receiverUser.id,
      pubId:selectedPub !== undefined ? selectedPub.id : -1,
      eMail,
      sms,
      receiverEmail : receiverUser.email

    }

   //console.log("new Mesaj ",newMessage)

    socket.emit('message',{name:"Nurdoğan " + socket.id ,message : newMessage})

    const result  = await postSaveWebMessagesApi(newMessage);

   //console.log("result ",result);
    if(result.status)
    {
     // setSuccess(true)
     setBody("");
   //  setSuccess(false)
    onClose()

      NotificationManager.success(<IntlMessages id="webMessages.newMessageModal.successMessage"/>);
    }
  }

  const getReceiverList = async (reqInst)=>{
    const resultCoordinator =await getUserByRoleIdAndInstApi(54,reqInst !== undefined ? reqInst : inst);
   // const resultEditor =await getUserByRoleIdAndInstApi(55,inst);
    
   if(resultCoordinator.data.some(x=>x.journalId === journalId))
   setReceiverUserList(  resultCoordinator.data.filter(x=>x.journalId === journalId))
   else
   setReceiverUserList(  resultCoordinator.data)

 
     
  }

  
  return (<div style={{zIndex:"1500 !important"}}>
  {/* <Modal className="modal-box modal-box-mail"   isOpen={open} id="newMessageModal"> */}
  <Dialog className="modal-box" maxWidth="md" fullWidth open={open}  onClose={onClose} >

      <ModalHeader className="modal-box-header bg-primary text-white">
      <IntlMessages id="webMessages.newMessageModal.title"/>
        <IconButton className="text-white"
                    onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </ModalHeader>
      <div className="modal-box-content d-flex flex-column">

      {/* <TextField
          id="required"
          label="To*"
          onChange={(event) => this.setState({to: event.target.value})}
          defaultValue={to}
          margin="normal"/>
        <TextField
          id="required"
          label="To*"
          onChange={(event) => this.setState({to: event.target.value})}
          defaultValue={to}
          margin="normal"/> */}
         <UserCell user={senderUser} title={<IntlMessages id="webMessages.sender"/>} isAuthorized={true}/>
         {receiverUser !== null && <UserCell user={receiverUser} title={<IntlMessages id="webMessages.receiver"/>} isAuthorized={isAuthorized}/>}
        { newMessageType === "detail" &&
          <div className="row"> 
         
            <div className="col-md-4">
              <div className="form-group">
                <TextField
                  id="institution"
                  select
                  label={<IntlMessages id="pub.institution" />}
                  value={inst}
                  SelectProps={{}}
                  margin="normal"
                  fullWidth
                  onChange={(e)=>{
                    e.preventDefault();
                    setInst(e.target.value);
                    setJournallist(config.journals.filter(x=>x.inst=== e.target.value))
                    getReceiverList(e.target.value);
                  }}
               
                >
                  <MenuItem key="AKM" value="AKM">
                    <IntlMessages id={"institution.AKM"} />
                  </MenuItem>
                  <MenuItem key="ATAM" value="ATAM">
                    <IntlMessages id={"institution.ATAM"} />
                  </MenuItem>
                  <MenuItem key="TDK" value="TDK">
                    <IntlMessages id={"institution.TDK"} />
                  </MenuItem>
                  <MenuItem key="TTK" value="TTK">
                    <IntlMessages id={"institution.TTK"} />
                  </MenuItem>
                </TextField>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <TextField
                  id="journalId"
                  select
                  label={<IntlMessages id="pub.journalId" />}
                  margin="normal" 
                  fullWidth
                  value={journalId}
                  onChange={(e)=>{
                    getReceiverList();
                    setJournalId(Number(e.target.value))
                  }}
                >
                  {journalList.map((option) => (
                    <MenuItem key={"key" + option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                    <MenuItem key={"key-1"} value={-1}>
                    <IntlMessages id="book" />
                    </MenuItem>
                </TextField>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <TextField
                  id="receiver"
                  select
                  label={<IntlMessages id="webMessages.receiver" />}
                  margin="normal" 
                  fullWidth
                  value={receiverUserId}
                  onChange={(e)=>setReceiverUserId(Number(e.target.value))}
                >
                  {receiverUserList.map((option) => (
                    <MenuItem key={"key" + option.id} value={option.id}>
                      {/* <IntlMessages id={"pub.categories." + option.name} /> */}
                   
                      { getFullName(option)}

                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
            }

            {
              isInstitution  ?
              <FormControl component="fieldset" className="m-3">
              <FormLabel component="legend"><IntlMessages id={"webMessages.newMessageModal.notificationType"} /></FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={eMail} onChange={(e)=>setEmail(e.target.checked)} name="gilad" />}
                  label={ <IntlMessages id={"webMessages.newMessageModal.eMail"} />}
                />
                <FormControlLabel 
                  control={<Checkbox disabled={ (isAuthorized && body.length >= 300) } checked={sms} onChange={(e)=>setSms(e.target.checked)}name="jason" />}
                  label={ <IntlMessages id={"webMessages.newMessageModal.sms"} />}
                />
               
              </FormGroup>
            </FormControl>

              : null 
            }
            {
              (isAuthorized && body.length >= 300) && <Alert color="danger">
                Toplamda 300 ve üzeri karakter sayısını geçtiğiniz için, mesajınızı SMS olarak gönderemezsiniz.
              </Alert>
            }
        <TextField
          id="required"
          label={ <IntlMessages id="webMessages.body"/>}
          onChange={(event) => {setBody(event.target.value); setSms(event.target.value.length>=300 ? false : sms);}}
          value={body}
          variant="outlined"
          multiline
          rows="5"
          rowsMax="10"
          margin="normal"/>
      </div>

      <div className="modal-box-footer">
        {/* <Button className="attach-file jr-btn text-muted">
          <i className="zmdi zmdi-attachment mr-2 zmdi-hc-2x"/> Attach File
        </Button> */}

        <Button disabled={body === ''} variant="contained" color="primary" onClick={() => {
          sendMessage();

        }}>
          <i className="zmdi zmdi-mail-send mr-2"/>  <IntlMessages id="webMessages.newMessageModal.sendMessage"/></Button>
      </div>

      <SweetAlert show={success} 
        success title={<IntlMessages id="webMessages.newMessageModal.successMessage"/>} 
        confirmBtnText={<IntlMessages id="button.ok"/>}
                    onConfirm={()=>{
                      setBody("");
                      setSuccess(false)
                     onClose()
                     
                    }
                      }>
                     
             </SweetAlert>

    {/* </Modal> */}
 </Dialog>
  </div>
   );
}


