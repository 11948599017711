import axios from 'axios';
import config from '../../config';
import { getAxiosConfigUserToken } from '../../util/utilFunction';

export const getWebMessagesAllApi = async()=>{
    const url = `${config.apiUrl}/v1/webMessages/`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const postSaveWebMessagesApi = (webMessages)=>{
    const url = `${config.apiUrl}/v1/webMessages/`;
    return axios.post(url,webMessages,getAxiosConfigUserToken())
    .then(response => response.data);
 } 
 export const putUpdateWebMessagesApi = (webMessages)=>{
   const url = `${config.apiUrl}/v1/webMessages/`;
   return axios.put(url,webMessages,getAxiosConfigUserToken())
   .then(response => response.data);
}
export const putUpdateWebMessagesByQuestionIdApi = (webMessages,questionId)=>{
   const url = `${config.apiUrl}/v1/webMessages/byQuestionId/${questionId}`;
   return axios.put(url,webMessages,getAxiosConfigUserToken())
   .then(response => response.data);
}

export const getWebMessagesByIdApi = async (id)=>{
   const url = `${config.apiUrl}/v1/webMessages/${id}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
export const getWebMessagesByUserIdApi = async(userId)=>{
    const url = `${config.apiUrl}/v1/webMessages/byUserId/${userId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getWebMessagesBySenderUserIdApi = async(userId)=>{
    const url = `${config.apiUrl}/v1/webMessages/bySenderUserId/${userId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getWebMessagesByReceiverUserIdApi = async(userId)=>{
    const url = `${config.apiUrl}/v1/webMessages/byReceiverUserId/${userId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getWebMessagesByPubIdApi = async(pubId)=>{
    const url = `${config.apiUrl}/v1/webMessages/byPubId/${pubId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getWebMessagesByInstApi = async(inst)=>{
     const url = `${config.apiUrl}/v1/webMessages/byInst/${inst}`;
     return await axios.get(url,getAxiosConfigUserToken())
     .then(response => response.data)
  }
  export const getWebMessagesByQuestionIdApi = async(questionId)=>{
    const url = `${config.apiUrl}/v1/webMessages/byQuestionId/${questionId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }