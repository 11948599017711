import axios from 'axios';
import config from '../../config';
import { getAxiosConfigUserToken } from '../../util/utilFunction';

export const getNotificationAllApi = async()=>{
    const url = `${config.apiUrl}/v1/notification/`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const postSaveNotificationApi = (notification)=>{
    const url = `${config.apiUrl}/v1/notification/`;
    return axios.post(url,notification,getAxiosConfigUserToken())
    .then(response => response.data);
 } 
 export const putUpdateNotificationApi = (notification)=>{
   const url = `${config.apiUrl}/v1/notification/`;
   return axios.put(url,notification,getAxiosConfigUserToken())
   .then(response => response.data);
}
 export const deletetNotificationByIdApi = async(id)=>{
   const url = `${config.apiUrl}/v1/notification/${id}`;
   return await axios.delete(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
export const getNotificationByIdApi = async (id)=>{
   const url = `${config.apiUrl}/v1/notification/${id}`;
   return await axios.get(url,getAxiosConfigUserToken())
   .then(response => response.data)
}
export const getNotificationByUserIdApi = async(userId)=>{
    const url = `${config.apiUrl}/v1/notification/byUserId/${userId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const getNotificationByPubIdApi = async(pubId)=>{
    const url = `${config.apiUrl}/v1/notification/byPubId/${pubId}`;
    return await axios.get(url,getAxiosConfigUserToken())
    .then(response => response.data)
 }
 export const putUpdateNotificationByUserIdApi = (notification,userId)=>{
   const url = `${config.apiUrl}/v1/notification/byUserId/${userId}`;
   return axios.put(url,notification,getAxiosConfigUserToken())
   .then(response => response.data);
}