import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "actions/Auth";


import config from "../config";
import SimpleCrypto from "simple-crypto-js";
const simpleCrypto = new SimpleCrypto(config.sk);



const signOutRequest = async () =>
 {   
//      await  auth.signOut()
//         .then(authUser => authUser)
//         .catch(error => error);
 //   return undefined;
    }



function* signInUserWithEmailPassword({payload}) {
    const {user, token} = payload;
    try {
   
            localStorage.setItem("userYaysis", simpleCrypto.encryptObject(user));
            localStorage.setItem("userTokenYaysis", token);
           //console.log("buaraya geldi sagas",user)
            yield put(userSignInSuccess(user));
    
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
       //console.log("ÇIkış sagasa geldi. User :",signOutUser)
        if (signOutUser === undefined) {
        //    localStorage.removeItem('userId');
           localStorage.removeItem('userTokenYaysis');
           localStorage.removeItem("userYaysis");
         
          
            yield put(userSignOutSuccess(signOutUser));

            window.location.href = config.mainPageUrl;
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        
        fork(signInUser),
        fork(signOutUser),
    ]);
}

