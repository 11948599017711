let envConfig = {};
const devConfig = {
  apiUrl: "http://localhost:3315",
//  userAuthApiUrl: "http://localhost:3301",
//  userCheckApiUrl: "http://localhost:3301/v1/user/check/otherApp",
  // apiUrl: "http://192.168.55.66:3305",
  // userAuthApiUrl: "http://192.168.55.66:3301",
  userCheckApiUrl: "https://testgiris.ayk.gov.tr/api/v1/user/check/otherApp",
  userAuthApiUrl: "https://testgiris.ayk.gov.tr/api/",
  //mainPageUrl: "http://localhost:3000/",
  mainPageUrl: "https://testgiris.ayk.gov.tr/",

  appUrl:"http://localhost:3315",
};
const testConfig = {
  apiUrl: "https://testyaysis.ayk.gov.tr/api/",
  mainPageUrl: "https://testgiris.ayk.gov.tr/",
  userCheckApiUrl: "https://testgiris.ayk.gov.tr/api/v1/user/check/otherApp",
  userAuthApiUrl: "https://testgiris.ayk.gov.tr/api/",
  appUrl:"https://testyaysis.ayk.gov.tr",

};
const prodConfig = {
  apiUrl: "https://yaysis2.ayk.gov.tr/api/",
  mainPageUrl: "https://giris.ayk.gov.tr/",
  userCheckApiUrl: "https://giris.ayk.gov.tr/api/v1/user/check/otherApp",
  userAuthApiUrl: "https://giris.ayk.gov.tr/api/",
  appUrl:"https://yaysis2.ayk.gov.tr",
};
if (process.env.REACT_APP_ENV === "development") {
  envConfig = devConfig;
} else if (process.env.REACT_APP_ENV === "test") {
  envConfig = testConfig;
} else if (process.env.REACT_APP_ENV === "production") {
  envConfig = prodConfig;
}
//export  {envConfig};
//console.log("env config",envConfig);
export default {
  apiUrl: envConfig.apiUrl,
  mainPageUrl: envConfig.mainPageUrl,
  userCheckApiUrl: envConfig.userCheckApiUrl,
  userAuthApiUrl: envConfig.userAuthApiUrl, 
  appUrl: envConfig.appUrl,

  educationVideoVisible : true ,


  defaultProfileImageUrl: "/uploads/images/user.jpg",

  copright: "Her hakkı saklıdır. T.C. Atatürk Kültür, Dil ve Tarih Yüksek Kurumu",
  coprightYear: "2021",

  sk: "BBSuserKey123",
  signOutUrl: "/bbs/mainPage",
  signInUrl: "/yts/mainPage",

  bitly: {
    url: "https://api-ssl.bitly.com/v4/shorten",
    guid: "Bk5rlZm619C",
    token: "5ccb9140fbe1be83dbfc752b65be1180d3dbfdaa",
  },

  intihalNet: {
    secretKey: "qUnLaXcBu-adCBSEmCES",
    sendUrl: "http://app.intihal.net/serviceintihal/document",
    checkUrl: "http://app.intihal.net/serviceintihal/check",
    resultUrl: "https://app.intihal.net/serviceintihal/proje",
  },

  timeConfig: {
    messageRefreshTime: 300000,
    notificationRefreshTime: 300000,
    varsayilanSonIncelemeTarihiKitap: 2, //2 ay,
    varsayilanSonIncelemeTarihiMakale: 1, //1 ay,
    yazarIcinSonDuzeltmeGonderimTarihKitap: 3, //ay
    yazarIcinSonDuzeltmeGonderimTarihMakale: 1, //ay
    inceleyiciIcinDuzletmeSonrasiTarihKitap: 30, //gün
    inceleyiciIcinDuzletmeSonrasiTarihMakale: 15, //gün
  },

  categories: [
    { index: 1 , name: "Telif Eserler" },
    { index: 2 , name: "İşlenmeli Yayınlar" },
    { index: 3 , name: "Ders Kitapları" },
    { index: 4 , name: "Tercümeler" },
    { index: 5 , name: "Resim ve Planlar" },
    { index: 6 , name: "Edebî Eserler" },
    { index: 7 , name: "Sözlükler" },
    { index: 8 , name: "Bibliyografik Eserler" },
    { index: 9 , name: "Yabancı ve Eski Belgeler" },
    { index: 10 , name: "Editöryal Eserler" },

  ],

  actions2: [
    {
      id: 1, 
      sms: true,
      email: true,
      name: "Eser, yazar tarafından sisteme yüklendi",
    },
    {
      id: 2,
      sms: true,
      email: true,
      name: "Eser, yazar tarafından geri çekildi",
    },
    {
      id: 3,
      sms: true,
      email: true,
      name: "Eser, koordinatör tarafından iade edildi.",
    },
    {
      id: 4,
      sms: true,
      email: true,
      name: "Eser, ön değerlendirme için yayın takip görevlisine atandı",
    },
    {
      id: 5,
      sms: true,
      email: true,
      name: "Eserin ön değerlendirme işlemi tamamlandı",
    },
    {
      id: 6,
      sms: true,
      email: true,
      name: "Eser, ön değerlendirme sonucu düzeltme isteğiyle yazara iade edildi.",
    },
    //{id:7, sms: true, email : true ,  name:"Eser, ön değerlendirme sonucu reddedildi."},
    {
      id: 8,
      sms: true,
      email: true,
      name: "Eser, Yayın Komisyonu gündemine eklenmek üzere koordinatöre gönderildi.",
    },
    {
      id: 9,
      sms: true,
      email: true,
      name: "Eser, Yayın Komisyonu kararıyla reddedildi.",
    },
    { id: 10, sms: true, email: true, name: "Eser, bir inceleyiciye atandı" },
    {
      id: 11,
      sms: true,
      email: true,
      name: "Eser, inceleyiciden geri çekildi.",
    },
    {
      id: 12,
      sms: true,
      email: true,
      name: "İnceleme görevi, inceleyici tarafından reddedildi",
    },
    {
      id: 13,
      sms: true,
      email: true,
      name: "İnceleme görevi, inceleyici tarafından kabul edildi",
    },
    {
      id: 14,
      sms: true,
      email: true,
      name: "Bir inceleyici inceleme sonucunda ONAY verdi.",
    },
    {
      id: 15,
      sms: true,
      email: true,
      name: "Bir inceleyici inceleme sonucunda DÜZELTME istedi",
    },
    {
      id: 16,
      sms: true,
      email: true,
      name: "Bir inceleyici inceleme sonucunda RET verdi.",
    },
    {
      id: 17,
      sms: true,
      email: true,
      name:
        "İnceleme sonucu, yayın takip görevlisi tarafından yazara gönderildi",
    },
    {
      id: 18,
      sms: true,
      email: true,
      name:
        "İnceleme sonucu, yayın takip görevlisi tarafından inceliyiciye iade edildi.",
    },
    {
      id: 19,
      sms: true,
      email: true,
      name: "Düzeltilen eser, yazar tarafından yayın takip görevlisine gönderdi",
    },
    {
      id: 20,
      sms: true,
      email: true,
      name: "Düzeltilen eser, yayın takip görevlisi tarafından inceleyiciye gönderildi",
    },
    {
      id: 21,
      sms: true,
      email: true,
      name: "Eser, Yayın Komisyonu kararıyla Yönetim Kuruluna gönderildi.",
    },
    { id: 22, sms: true, email: true, name: "Esere ilişkin Yayın Komisyonu kararı geldi." },
    { id: 23, sms: true, email: true, name: "Esere ilişkin Yönetim Kurulu Kararı geldi." },
    {
      id: 24,
      sms: true,
      email: true,
      name: "Eserin basımı, Yönetim Kurulu kararıyla onaylandı.",
    },
    {
      id: 25,
      sms: true,
      email: true,
      name: "Eserin basımı, Yönetim Kurulu kararıyla reddedildi",
    },
    {
      id: 26,
      sms: true,
      email: true,
      name: "Eser, yayın takip görevlisi tarafından düzeltme isteğiyle yazara gönderildi.",
    },
    {
      id: 27,
      sms: true,
      email: true,
      name: "Eser, koordinatör tarafından Yayın Komisyonu gündemine eklendi.",
    },
    {
      id: 28,
      sms: true,
      email: true,
      name: "Eser, Yönetim Kuruluna gönderilmek üzere koordinatöre gönderildi.",
    },
    {
      id: 29,
      sms: true,
      email: true,
      name: "Eser, Yayın Komisyonundan geri çekildi veya iade edildi.",
    },
  ],

  faqs: [
    {
      name: "Yazar İşleri",
      questions: [1, 2, 3 ],
    },
    {
      name: "İnceleyici İşleri",
      questions: [1, 2, 3],
    },
    {
      name: "genel",
      questions: [1, 2, 3 ],
    },
  ],

  titleListLong: [
    "Araştırma Profesörü",
    "Doçent",
    "Doktor",
    "Doktor Öğretim Üyesi",
    "Yardımcı Doçent",
    "Ordinaryüs",
    "Profesör",
    "Araştırma Görevlisi",
    "Çevirmen",
    "Okutman",
    "Öğretim Görevlisi",
    "Öğretim Planlamacısı",
    "Uzman",
    "Öğretmen",
    "Diğer"
  ],

  degreeListLong: [
    "Ortaöğretim",
    "Önlisans",
    "Lisans",
    "Lisans-Anadal",
    "Lisans-Yandal",
    "Lisans Tamamlama",
    "Bilimsel Hazırlık",
    "Yüksek Lisans",
    "Yüksek Lisans - Tezli",
    "Yüksek Lisans - Tezsiz",
    "Doktora",
  ],

  priminaryExaminationQuestions: [1, 2, 3, 4],
  pubReviewQuestions: [1, 2, 3, 4, 5],

  docTypes:[
    "Dosya",
 "Eser (PDF)",
 "Eser (Word)",
"Kaynakça (Excel)",
 "Benzerlik Raporu (PDF)",
 "Yayınevi İzin Forumu (PDF)",
"Eserin Orijinali (PDF)",
"Diğer (*)",
 "İnceleme Eki (PDF)",
 "İnceleme Eki (Word)",
"Kitap Cilt (PDF)",
"Harcama Pusulası",
 "Sözleşme Formu",
 "Yayın İlkesi",
"Yazardan İnceleyiciye Bilgi Notu",
"Telif Devir Sözleşme Formu",
"Eserin Mizanpajlı Hâli",
 "Eserin Kapak Resmi",
"Yazı Fontu",
"Etik Kurulu Onay Belgesi",
"Taslak Yazar Sözleşme Formu",
  ],
  journals: [
    //  { id: 1, name: "Türk Dili"                          , inst: "TDK" , keyword:[5,7 ] , abstract:[200,250] , english:false },
    {
      id: 2,
      name: "Türk Dünyası",
      inst: "TDK",
      keyword: [5, 7],
      abstract: [200, 250],
      english: true,
    },
    {
      id: 3,
      name: "TDAY Belleten",
      inst: "TDK",
      keyword: [3, 7],
      abstract:  [200, 250],
      english: true,
    },
    {
      id: 4,
      name: "Belleten",
      inst: "TTK",
      keyword: [5, 8],
      abstract: [200, 250],

      english: true,
    },
    {
      id: 5,
      name: "Belgeler",
      inst: "TTK",
      keyword: [5, 8],
      abstract: [200, 250],

      english: true,
    },
    {
      id: 6,
      name: "Atatürk Araştırma Merkezi Dergisi",
      inst: "ATAM",
      keyword: [3, 10],
      abstract: [200, 2000],
      english: true,
    },
    {
      id: 7,
      name: "Erdem",
      inst: "AKM",
      keyword: [5, 8],
      abstract: [350, 400],
      english: true,
    },
    {
      id: 8,
      name: "Bilge",
      inst: "AKM",
      keyword: [5, 8],
      abstract: [350, 400],
      english: true,
    },
    {
      id: 9,
      name: "Arış",
      inst: "AKM",
      keyword: [5, 8],
      abstract: [200, 300],
      english: true,
    },
    {
      id: 10,
      name: "Höyük",
      inst: "TTK",
      keyword: [5, 8],
      abstract: [200, 250],
      english: true,
    },
  ],
  institutions: [
    {
      id: 1,
        inst: "TDK",
      instLongName: "Türk Dil Kurumu",
      instLong: "Türk Dil Kurumu'nun",
      messages: "",
      userId : 4,
    },
    { 
      id: 2,
      inst: "TTK",
      instLongName: "Türk Tarih Kurumu",
      instLong: "Türk Tarih Kurumu'nun",
      messages: "Evrakı ıslak imzalı 1 nüsha  PTT Kargo ile Kurum adına alıcı ödemeli şeklinde 305200792 müşteri kodu ile gönderebilirsiniz. Alıcı Bilgileri: Türk Tarih Kurumu Başkanlığı (Görkem GÖK) Tel: 0312 310 23 68 / 207",
      userId : 5,
    },
    {
      id: 3,
      inst: "ATAM",
      instLongName: "Atatürk Araştırma Merkezi Başkanlığı",
      instLong: "Atatürk Araştırma Merkezi Başkanlığı'nın",
      messages: "",
      userId : 3,
    },
    {
      id: 4,
      inst: "AKM",
      instLongName: "Atatürk Kültür Merkezi Başkanlığı",
      instLong: "Atatürk Kültür Merkezi Başkanlığı'nın",
      messages: "",
      userId : 2,
    },
    {
      id: 5,
      inst: "YK",
      instLongName: "Atatürk Kültür Dil ve Tarih Yüksek Kurumu",
      instLong: "Atatürk Kültür Dil ve Tarih Yüksek Kurumu Başkanlığı'nın",
      messages: "",
      userId : 2,
    }
  ],

  books: [
    {
      id: 1,
     
      inst: "TDK",
      instLongName: "Türk Dil Kurumu",
      instLong: "Türk Dil Kurumunun",
      keyword: [5, 7],
      abstract: [400, 450],
      english: true,
    },
    {
      id: 2,
      name: "Kitap",
      inst: "TTK",
      instLongName: "Türk Tarih Kurumu",
      instLong: "Türk Tarih Kurumunun",
      keyword: [5, 8],
      abstract: [400, 450],
      english: true,
    },
    {
      id: 3,
      name: "Kitap",
      inst: "ATAM",
      instLongName: "Atatürk Araştırma Merkezi Başkanlığı",
      instLong: "Atatürk Araştırma Merkezi Başkanlığının",
      keyword: [3, 8],
      abstract: [400, 2000],
      english: true,
    },
    {
      id: 4,
      name: "Kitap",
      inst: "AKM",
      instLongName: "Atatürk Kültür Merkezi Başkanlığı",
      instLong: "Atatürk Kültür Merkezi Başkanlığının",
      keyword: [5, 8],
      abstract: [400, 450],
      english: true,
    },
  ],

  meetingUnit: [
    {
      id: 1,
      roleId: 145,
      name: "Türk Dili Araştırmaları Yıllığı-Belleten Dergisi Yazı Kurulu",
      inst: "TDK",
    },
    {
      id: 2,
      roleId: 146,
      name: "Türk Dili Yazı Kurulu",
      inst: "TDK",
    },
    {
      id: 3,
      roleId: 147,
      name: "Türk Dünyası Dergisi Yazı Kurulu",
      inst: "TDK",
    },
    { id: 4, roleId: 149, name: "Yayın Kolu", inst: "TDK" },
    {
      id: 5,
      roleId: 134,
      name: "Yayın Komisyonu",
      inst: "ATAM",
    },
    {
      id: 6,
      roleId: 177,
      name: "Süreli Yayın Komisyonu",
      inst: "ATAM",
    },
    { id: 7, roleId: 121, name: "Süreli (Arış) Yayın Kurulu", inst: "AKM" },
    {
      id: 8,
      roleId: 122,
      name: "Süreli (Erdem) Yayın Kurulu",
      inst: "AKM",
    },
    {
      id: 9,
      roleId: 123,
      name: "Süresiz (Eser) Yayın Komisyonu",
      inst: "AKM",
    },
    {
      id: 10,
      roleId: 159,
      name: "Süreli Yayınlar Komisyonu",
      inst: "TTK",
    },
    {
      id: 11,
      roleId: 154,
      name: "Eser Yayın Komisyonu",
      inst: "TTK",
    },
    {
      id: 12,
      roleId: 172,
      name: "Arkeolojik Kazı ve Höyük Yayın Komisyonu",
      inst: "TTK",
    },
  ],

  pubPrinting: {
    pubType: ["Kitap", "Kitap Sözlük", "Dergi", "CD", "Ansiklopedi","Makale", "Diğer"],
    printType: ["Yeni Yayın", "Süreli Yayın", "Tıpkı Basım","E-Yayın","E-Yayın ve Matbuu Yayın"],
    // process: [
    //   "Basım Aşamasında",
    //   "İncelemede",
    //   "Tamamlandı",
    //   "Yönetim Kuruluna Sunuldu",
    // ],
    process: [
      "Bilimsel Süreci Tamamlanan",
      "Ödeme İşlemi Evrak Sürecinde",
      "Baskı Sürecinde",
      "Tamamlandı",
    ],
  },

  telifGostergeler: [
    {
      index: 1,
      anaBaslik: "Telif Eserler",
      alt: [
        {
          baslik: "a",
          detay: "Türkçe telif (kaynak, ansiklopedik veya monografik) eserler",
          gosterge: [80, 85, 90],
        },
        {
          baslik: "b",
          detay:
            "Yabancı dil telif (kaynak, ansiklopedik veya monografik) eserler",
          gosterge: [80, 85, 90],
        },
        {
          baslik: "c",
          detay: "Vülgarize (Popüler = halka hitap eden)",
          gosterge: [40, 45, 50],
        },
      ],
    },
    {
      index: 2,
      anaBaslik:
        "İşlenmeli Yayınlar (Diğer bir veya birçok eserden istifade edilerek meydana getirilip de bu esere nispetle müstakil olmayan, kısmen müstakil olan fikir ve sanat mahsulleri)",
      alt: [
        {
          baslik: "a",
          detay:
            "Tür değişikliği (roman, hikaye, şiir ve tiyatro piyesi gibi eserlerden birinin diğer bir türe çevrilmesi)",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "b",
          detay:
            "Külliyat (bir eser sahibinin bütün veya aynı cinsten olan eserlerin külliyat haline konması)",
          gosterge: [24, 27, 30],
        },
        {
          baslik: "c",
          detay:
            "Derleme (belli bir maksada göre ve özel bir plan dahilinde seçme ve toplama)",
          gosterge: [24, 27, 30],
        },
        {
          baslik: "ç",
          detay:
            "Henüz yayımlanmamış bir eserin bilimsel araştırma ve çalışma sonucunda yayımlanmaya elverişli hale getirilmesi",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "d",
          detay: "Başkasına ait bir eserin izah ve şerhi veya kısaltması",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "e",
          detay: "Eleştirmeli metin (edisyon kritik)",
          gosterge: [44, 50, 55],
        },
        {
          baslik: "f",
          detay: "Çeviri yazı (Transkripsiyon)",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "g",
          detay: "Eski harfli metinlerin yeni yazıya çevrilmesi",
          gosterge: [40, 45, 50],
        },
      ],
    },
    {
      index: 3,
      anaBaslik:
        "Ders kitapları (7 nci maddenin son fıkrası gereğince kamu kurum ve kuruluşlarının yapacakları ödemede uygulanmaz.)",
      // gosterge: [50, 55, 60],
      alt: [
        {
          baslik: "a",
          detay: "Ders kitapları",
          gosterge: [50, 55, 60],
        },
      ],
    },
    {
      index: 4,
      anaBaslik: "Tercümeler, yabancı ve eski belgeler",
      alt: [
        {
          baslik: "a",
          detay: "Yabancı dillerden Türkçeye tercüme",
          gosterge: [60, 65, 70],
        },
        {
          baslik: "b",
          detay:
            "Rusça, Çince gibi öğretimi Türkiye’de yaygınlaştırılmamış dillerden Türkçeye tercüme",
          gosterge: [70, 75, 80],
        },
        {
          baslik: "c",
          detay:
            "Uzmanı nadir bulunan eski dillerden Türkçe’ye tercüme (Sümerce, Hititçe, Eski Yunanca Latince vb.)",
          gosterge: [70, 75, 80],
        },
        {
          baslik: "ç",
          detay: "Türkçeden yabancı dillere tercüme",
          gosterge: [70, 75, 80],
        },
        {
          baslik: "d",
          detay: "Basılmış belgelerin olduğu gibi yayımlanması",
          gosterge: [16, 18, 20],
        },
        {
          baslik: "e",
          detay: "Yabancı dilde yazılmış belgelerin hazırlanıp yayımlanması",
          gosterge: [20, 23, 26],
        },
        {
          baslik: "f",
          detay: "Osmanlıcadan sadeleştirme",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "g",
          detay: "Yayımlanmamış Osmanlıca yayınların tıpkı basımı",
          gosterge: [8, 10, 12],
        },
      ],
    },
    {
      index: 5,
      anaBaslik: "Orjinal (ilk defa yayımlanan) resim ve planlar",
      gosterge: [24, 27, 30],
      alt: [
        {
          baslik: "a",
          detay: "Orjinal (ilk defa yayımlanan) resim ve planlar",
          gosterge: [24, 27, 30],
        },
      ],
    },
    {
      index: 6,
      anaBaslik:
        "Makaleler  (Makaleler için konusuna göre diğer maddelerde belirtilen ücretler ödenir.)",
      gosterge: [0, 0, 0],
      alt: [
        {
          baslik: "a",
          detay: "Makaleler",
          gosterge: [0, 0, 0],
        },
      ],
    },
    {
      index: 7,
      anaBaslik:
        "Edebi Eserler (Şiir ve manzum eserlerinden 20 mısra kadar eser 20 mısra   olarak kabul edilir. 20 mısradan sonraki her mısra için  gösterge rakamları ayrıca uygulanır.  Her 20 mısra için)",
      alt: [
        {
          baslik: "a",
          detay: "Telif şiir veya manzume",
          gosterge: [80, 85, 90],
        },
        {
          baslik: "b",
          detay: "Türkçeden yabancı dile manzum olarak tercüme",
          gosterge: [60, 65, 70],
        },
        {
          baslik: "c",
          detay: "Yabancı dilden Türkçeye manzum olarak tercüme",
          gosterge: [50, 55, 60],
        },
        {
          baslik: "ç",
          detay: "Külliyat",
          gosterge: [24, 27, 30],
        },
        {
          baslik: "d",
          detay: "Derleme",
          gosterge: [24, 27, 30],
        },
        {
          baslik: "e",
          detay: "Sadeleştirme",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "f",
          detay: "Eski harflerin metinlerinin yeni yazıya çevrilmesi",
          gosterge: [40, 45, 50],
        },
        {
          baslik: "g",
          detay: "Diğer edebi eserler ",
          gosterge: [50, 55, 60],
        },
      ],
    },
    {
      index: 8,
      anaBaslik:
        "Tiyatro, Opera ve Bale Yürütme kurullarının seçeceği bir komisyon tarafından yapılacak ücret tespiti yürütme kurullarının önerisi üzerine Yönetim Kurulunun kararı ile kesinleşir",
      gosterge: [0, 0, 0],
      alt: [
        {
          baslik: "a",
          detay: "Tiyatro, Opera ve Bale",
          gosterge: [0, 0, 0],
        },
      ],
    },

    {
      index: 9,
      anaBaslik: "Sözlükler",
      alt: [
        {
          baslik: "a",
          detay: "Türkçeden Türkçeye",
          gosterge: [60, 65, 70],
        },
        {
          baslik: "b",
          detay: "Türkçe-Yabancı dil",
          gosterge: [60, 65, 70],
        },
        {
          baslik: "c",
          detay: "Yabancı dil-Türkçe",
          gosterge: [60, 65, 70],
        },
        {
          baslik: "ç",
          detay: "Yabancı dil-Türkçe",
          gosterge: [24, 27, 30],
        },
      ],
    },
    {
      index: 10,
      anaBaslik:
        "Tiyatro, konser, opera, bale gibi uygulamalar için, uygulanacak kurum veya derneğin mevzuatına göre ödeme yapılır.",
      gosterge: [0, 0, 0],
      alt: [
        {
          baslik: "a",
          detay:
            "Tiyatro, konser, opera, bale gibi uygulamalar için, uygulanacak kurum veya derneğin mevzuatına göre ödeme yapılır.",
          gosterge: [0, 0, 0],
        },
      ],
    },
    {
      index: 11,
      anaBaslik:
        "Telif eserlerde bibliyografik eser ve indeks ve bibliyografik eserler.",
      gosterge: [40, 45, 50],
      alt: [
        {
          baslik: "a",
          detay:
            "Telif eserlerde bibliyografik eser ve indeks ve bibliyografik eserler.",
          gosterge: [40, 45, 50],
        },
      ],
    },
    {
      index: 12,
      anaBaslik: "Kitap kapağı",
      alt: [
        {
          baslik: "a",
          detay: "Üç renkli kitap kapağının tamamı için",
          gosterge: [50, 55, 60],
        },
        {
          baslik: "b",
          detay: "Çok renkli kitap kapağının tamamı için",
          gosterge: [60, 65, 70],
        },
      ],
    },
  ],

  pubPrintingType: [
    "Yeni Yayın",
    "Tekrar baskı",
    "İlaveli baskı",
    "Yeni baskı",
    "E-Yayın",
  ],
  colors: [
    "blue",
    "indigo",
    "purple",
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "cyan",
    "white",
    "gray",
    "gray-dark",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ],

  titleList: [
    "Dr Öğretim Üyesi",
    "Prof.Dr.",
    "Doç.Dr.",
    "Öğretim Görevlisi",
    "Araştırma Görevlisi",
    "Dr.",
    "Öğretmen",
    "Diğer",
  ],
  mounth: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  citys: [
    "",
    "Adana",
    "Adıyaman",
    "Afyon",
    "Ağrı",
    "Amasya",
    "Ankara",
    "Antalya",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkari",
    "Hatay",
    "Isparta",
    "Mersin",
    "İstanbul",
    "İzmir",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Kahramanmaraş",
    "Mardin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Rize",
    "Sakarya",
    "Samsun",
    "Siirt",
    "Sinop",
    "Sivas",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Şanlıurfa",
    "Uşak",
    "Van",
    "Yozgat",
    "Zonguldak",
    "Aksaray",
    "Bayburt",
    "Karaman",
    "Kırıkkale",
    "Batman",
    "Şırnak",
    "Bartın",
    "Ardahan",
    "Iğdır",
    "Yalova",
    "Karabük",
    "Kilis",
    "Osmaniye",
    "Düzce",
  ],

  educationLevels: [
    "",
    "Okur-Yazar Değil",
    "Okur-Yazar",
    "İlkokul",
    "Ortaokul",
    "Lise",
    "Lisans",
    "YüksekLisans",
    "Doktora",
  ],

  fileAcceptExtension: [
    "video/x-flv",
    "video/mp4",
    "application/x-mpegURL",
    "video/MP2T",
    "video/3gpp",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "audio/basic",
    "audio/mid",
    "audio/mpeg	RFC 3003",
    "audio	audio/mp4",
    "audio/x-mpegurl",
    "Vorbis	audio/ogg	RFC 5334",
    "audio/vnd.wav",
    "image/cgm",
    "image/fits",
    "image/g3fax",
    "image/gif",
    "image/ief",
    "image/jp2",
    "image/jpeg",
    "image/jpm",
    "image/jpx",
    "image/naplps",
    "image/png",
    "image/prs.btif",
    "image/prs.pti",
    "image/t38",
    "image/tiff",
    "image/tiff-fx",
    "image/vnd.adobe.photoshop",
    "image/vnd.cns.inf2",
    "image/vnd.djvu",
    "image/vnd.dwg",
    "image/vnd.dxf",
    "image/vnd.fastbidsheet",
    "image/vnd.fpx",
    "image/vnd.fst",
    "image/vnd.fujixerox.edmics-mmr",
    "image/vnd.fujixerox.edmics-rlc",
    "image/vnd.globalgraphics.pgb",
    "image/vnd.microsoft.icon",
    "image/vnd.mix",
    "image/vnd.ms-modi",
    "image/vnd.net-fpx",
    "image/vnd.sealed.png",
    "image/vnd.sealedmedia.softseal.gif",
    "image/vnd.sealedmedia.softseal.jpg",
    "image/vnd.svf",
    "image/vnd.wap.wbmp",
    "image/vnd.xiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
  ],
  countries: [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ],
  countriesTR: [
    { value: "TR", label: "Türkiye" },
    { value: "VI", label: "ABD Virgin Adaları" },
    { value: "AF", label: "Afganistan" },
    { value: "AX", label: "Aland Adaları" },
    { value: "DE", label: "Almanya" },
    { value: "US", label: "Amerika Birleşik Devletleri" },
    { value: "UM", label: "Amerika Birleşik Devletleri Küçük Dış Adaları" },
    { value: "AS", label: "Amerikan Samoası" },
    { value: "AD", label: "Andora" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarktika" },
    { value: "AG", label: "Antigua ve Barbuda" },
    { value: "AR", label: "Arjantin" },
    { value: "AL", label: "Arnavutluk" },
    { value: "AW", label: "Aruba" },
    { value: "QU", label: "Avrupa Birliği" },
    { value: "AU", label: "Avustralya" },
    { value: "AT", label: "Avusturya" },
    { value: "AZ", label: "Azerbaycan" },
    { value: "BS", label: "Bahamalar" },
    { value: "BH", label: "Bahreyn" },
    { value: "BD", label: "Bangladeş" },
    { value: "BB", label: "Barbados" },
    { value: "EH", label: "Batı Sahara" },
    { value: "BZ", label: "Belize" },
    { value: "BE", label: "Belçika" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BY", label: "Beyaz Rusya" },
    { value: "BT", label: "Bhutan" },
    { value: "ZZ", label: "Bilinmeyen veya Geçersiz Bölge" },
    { value: "AE", label: "Birleşik Arap Emirlikleri" },
    { value: "GB", label: "Birleşik Krallık" },
    { value: "BO", label: "Bolivya" },
    { value: "BA", label: "Bosna Hersek" },
    { value: "BW", label: "Botsvana" },
    { value: "BV", label: "Bouvet Adası" },
    { value: "BR", label: "Brezilya" },
    { value: "BN", label: "Brunei" },
    { value: "BG", label: "Bulgaristan" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cape Verde" },
    { value: "GI", label: "Cebelitarık" },
    { value: "DZ", label: "Cezayir" },
    { value: "CX", label: "Christmas Adası" },
    { value: "DJ", label: "Cibuti" },
    { value: "CC", label: "Cocos Adaları" },
    { value: "CK", label: "Cook Adaları" },
    { value: "TD", label: "Çad" },
    { value: "CZ", label: "Çek Cumhuriyeti" },
    { value: "CN", label: "Çin" },
    { value: "DK", label: "Danimarka" },
    { value: "DM", label: "Dominik" },
    { value: "DO", label: "Dominik Cumhuriyeti" },
    { value: "TL", label: "Doğu Timor" },
    { value: "EC", label: "Ekvator" },
    { value: "GQ", label: "Ekvator Ginesi" },
    { value: "SV", label: "El Salvador" },
    { value: "ID", label: "Endonezya" },
    { value: "ER", label: "Eritre" },
    { value: "AM", label: "Ermenistan" },
    { value: "EE", label: "Estonya" },
    { value: "ET", label: "Etiyopya" },
    { value: "FK", label: "Falkland Adaları (Malvinalar)" },
    { value: "FO", label: "Faroe Adaları" },
    { value: "MA", label: "Fas" },
    { value: "FJ", label: "Fiji" },
    { value: "CI", label: "Fildişi Sahilleri" },
    { value: "PH", label: "Filipinler" },
    { value: "PS", label: "Filistin Bölgesi" },
    { value: "FI", label: "Finlandiya" },
    { value: "FR", label: "Fransa" },
    { value: "GF", label: "Fransız Guyanası" },
    { value: "TF", label: "Fransız Güney Bölgeleri" },
    { value: "PF", label: "Fransız Polinezyası" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GH", label: "Gana" },
    { value: "GN", label: "Gine" },
    { value: "GW", label: "Gine-Bissau" },
    { value: "GD", label: "Granada" },
    { value: "GL", label: "Grönland" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GY", label: "Guyana" },
    { value: "ZA", label: "Güney Afrika" },
    { value: "GS", label: "Güney Georgia ve Güney Sandwich Adaları" },
    { value: "KR", label: "Güney Kore" },
    { value: "CY", label: "Güney Kıbrıs Rum Kesimi" },
    { value: "GE", label: "Gürcistan" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Adası ve McDonald Adaları" },
    { value: "IN", label: "Hindistan" },
    { value: "IO", label: "Hint Okyanusu İngiliz Bölgesi" },
    { value: "NL", label: "Hollanda" },
    { value: "AN", label: "Hollanda Antilleri" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong SAR - Çin" },
    { value: "HR", label: "Hırvatistan" },
    { value: "IQ", label: "Irak" },
    { value: "VG", label: "İngiliz Virgin Adaları" },
    { value: "IR", label: "İran" },
    { value: "IE", label: "İrlanda" },
    { value: "ES", label: "İspanya" },
    { value: "IL", label: "İsrail" },
    { value: "SE", label: "İsveç" },
    { value: "CH", label: "İsviçre" },
    { value: "IT", label: "İtalya" },
    { value: "IS", label: "İzlanda" },
    { value: "JM", label: "Jamaika" },
    { value: "JP", label: "Japonya" },
    { value: "JE", label: "Jersey" },
    { value: "KH", label: "Kamboçya" },
    { value: "CM", label: "Kamerun" },
    { value: "CA", label: "Kanada" },
    { value: "ME", label: "Karadağ" },
    { value: "QA", label: "Katar" },
    { value: "KY", label: "Kayman Adaları" },
    { value: "KZ", label: "Kazakistan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "CO", label: "Kolombiya" },
    { value: "KM", label: "Komorlar" },
    { value: "CG", label: "Kongo" },
    { value: "CD", label: "Kongo Demokratik Cumhuriyeti" },
    { value: "CR", label: "Kosta Rika" },
    { value: "KW", label: "Kuveyt" },
    { value: "KP", label: "Kuzey Kore" },
    { value: "MP", label: "Kuzey Mariana Adaları" },
    { value: "CU", label: "Küba" },
    { value: "KG", label: "Kırgızistan" },
    { value: "LA", label: "Laos" },
    { value: "LS", label: "Lesotho" },
    { value: "LV", label: "Letonya" },
    { value: "LR", label: "Liberya" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Litvanya" },
    { value: "LB", label: "Lübnan" },
    { value: "LU", label: "Lüksemburg" },
    { value: "HU", label: "Macaristan" },
    { value: "MG", label: "Madagaskar" },
    { value: "MO", label: "Makao S.A.R. Çin" },
    { value: "MK", label: "Makedonya" },
    { value: "MW", label: "Malavi" },
    { value: "MV", label: "Maldivler" },
    { value: "MY", label: "Malezya" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "IM", label: "Man Adası" },
    { value: "MH", label: "Marshall Adaları" },
    { value: "MQ", label: "Martinik" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Meksika" },
    { value: "FM", label: "Mikronezya Federal Eyaletleri" },
    { value: "MD", label: "Moldovya Cumhuriyeti" },
    { value: "MC", label: "Monako" },
    { value: "MS", label: "Montserrat" },
    { value: "MR", label: "Moritanya" },
    { value: "MZ", label: "Mozambik" },
    { value: "MN", label: "Moğolistan" },
    { value: "MM", label: "Myanmar" },
    { value: "EG", label: "Mısır" },
    { value: "NA", label: "Namibya" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NE", label: "Nijer" },
    { value: "NG", label: "Nijerya" },
    { value: "NI", label: "Nikaragua" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Adası" },
    { value: "NO", label: "Norveç" },
    { value: "CF", label: "Orta Afrika Cumhuriyeti" },
    { value: "UZ", label: "Özbekistan" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua Yeni Gine" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Polonya" },
    { value: "PT", label: "Portekiz" },
    { value: "PR", label: "Porto Riko" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romanya" },
    { value: "RW", label: "Ruanda" },
    { value: "RU", label: "Rusya Federasyonu" },
    { value: "SH", label: "Saint Helena" },
    { value: "KN", label: "Saint Kitts ve Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "PM", label: "Saint Pierre ve Miquelon" },
    { value: "VC", label: "Saint Vincent ve Grenadinler" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome ve Principe" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seyşeller" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapur" },
    { value: "SK", label: "Slovakya" },
    { value: "SI", label: "Slovenya" },
    { value: "SB", label: "Solomon Adaları" },
    { value: "SO", label: "Somali" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Surinam" },
    { value: "SY", label: "Suriye" },
    { value: "SA", label: "Suudi Arabistan" },
    { value: "SJ", label: "Svalbard ve Jan Mayen" },
    { value: "SZ", label: "Svaziland" },
    { value: "RS", label: "Sırbistan" },
    { value: "CS", label: "Sırbistan-Karadağ" },
    { value: "CL", label: "Şili" },
    { value: "TJ", label: "Tacikistan" },
    { value: "TZ", label: "Tanzanya" },
    { value: "TH", label: "Tayland" },
    { value: "TW", label: "Tayvan" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad ve Tobago" },
    { value: "TN", label: "Tunus" },
    { value: "TC", label: "Turks ve Caicos Adaları" },
    { value: "TV", label: "Tuvalu" },
    { value: "TM", label: "Türkmenistan" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukrayna" },
    { value: "OM", label: "Umman" },
    { value: "UY", label: "Uruguay" },
    { value: "QO", label: "Uzak Okyanusya" },
    { value: "JO", label: "Ürdün" },
    { value: "VU", label: "Vanuatu" },
    { value: "VA", label: "Vatikan" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "WF", label: "Wallis ve Futuna" },
    { value: "YE", label: "Yemen" },
    { value: "NC", label: "Yeni Kaledonya" },
    { value: "NZ", label: "Yeni Zelanda" },
    { value: "GR", label: "Yunanistan" },
    { value: "ZM", label: "Zambiya" },
    { value: "ZW", label: "Zimbabve" },
  ],

  languagesTR: [
    { value: "Türkçe" },
    { value: "İngilizce" },
    { value: "Almanca" },
    { value: "Arapça" },
    { value: "Azerice" },
    { value: "Kürtçe" },
    { value: "Çince (Basitleştirilmiş)" },
    { value: "Çince (Geleneksel)" },
    { value: "Fransızca" },
    { value: "Rusça" },
    { value: "Farsça" },
    { value: "Filipince" },
    { value: "İspanyolca" },
    { value: "İsveççe" },
    { value: "İtalyanca" },
    { value: "Japonca" },
    { value: "Afrikaanca" },
    { value: "Amharca" },
    { value: "Arnavutça" },
    { value: "Baskça" },
    { value: "Batı Frizcesi" },
    { value: "Belarusça" },
    { value: "Bengalce" },
    { value: "Boşnakça" },
    { value: "Bulgarca" },
    { value: "Burmaca" },
    { value: "Cava Dili" },
    { value: "Cebuano" },
    { value: "Çekçe" },
    { value: "Danca" },
    { value: "Endonezce" },
    { value: "Ermenice" },
    { value: "Esperanto" },
    { value: "Estonca" },
    { value: "Felemenkçe" },
    { value: "Fince" },
    { value: "Galce" },
    { value: "Galiçyaca" },
    { value: "Güceratça" },
    { value: "Güney Sotho dili" },
    { value: "Gürcüce" },
    { value: "Hausa dili" },
    { value: "Haiti Kreyolu" },
    { value: "Hawaii dili" },
    { value: "Hırvatça" },
    { value: "Hintçe" },
    { value: "Hmong Dili" },
    { value: "İbo dili" },
    { value: "İbranice" },
    { value: "İrlandaca" },
    { value: "İskoç Gaelcesi" },
    { value: "İzlandaca" },
    { value: "Kannada dili" },
    { value: "Katalanca" },
    { value: "Kazakça" },
    { value: "Kırgızca" },
    { value: "Kmerce" },
    { value: "Korece" },
    { value: "Korsikaca" },
    { value: "Lao dili" },
    { value: "Latince" },
    { value: "Lehçe" },
    { value: "Letonca" },
    { value: "Litvanca" },
    { value: "Lüksemburgca" },
    { value: "Makedonca" },
    { value: "Macarca" },
    { value: "Malayalam dili" },
    { value: "Malayca" },
    { value: "Malgaşça" },
    { value: "Maltaca" },
    { value: "Maori dili" },
    { value: "Marathi" },
    { value: "Moğolca" },
    { value: "Nepalce" },
    { value: "Norveççe" },
    { value: "Nyanja" },
    { value: "Özbekçe" },
    { value: "Pencapça" },
    { value: "Peştuca" },
    { value: "Portekizce" },
    { value: "Rumence" },
    { value: "Samoa dili" },
    { value: "Seylanca" },
    { value: "Shona" },
    { value: "Sırpça" },
    { value: "Sindhi" },
    { value: "Slovakça" },
    { value: "Slovence" },
    { value: "Somalice" },
    { value: "Sunda Dili" },
    { value: "Svahili" },
    { value: "Tacikçe" },
    { value: "Tamilce" },
    { value: "Tayca" },
    { value: "Telugu dili" },
    { value: "Ukraynaca" },
    { value: "Urduca" },
    { value: "Vietnamca" },
    { value: "Yidiş" },
    { value: "Yorubaca" },
    { value: "Yunanca" },
    { value: "Zosa dili" },
    { value: "Zuluca" },
  ],
};
