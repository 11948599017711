import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IntlMessages from "util/IntlMessages";
import { Avatar, Button, Chip } from '@material-ui/core';
import config from '../../../../config';
import { getFullName } from '../../../../util/utilFunction';



function ProcessTable({processList,isAdmin,showComment,classStyle,backProcessClick}) {

  




  return (
    <Paper className={classStyle}>
    <div className="flex-auto">
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
           
            <TableCell align="left"><IntlMessages id="process.index" /></TableCell>
            <TableCell align="left"><IntlMessages id="process.action" /></TableCell>
           {showComment && <TableCell align="left"><IntlMessages id="process.userComments" /></TableCell>}
            <TableCell align="left"><IntlMessages id="process.actionDate" /></TableCell>
        {isAdmin ? [
        <TableCell align="left"><IntlMessages id="process.userId" /></TableCell>,
            <TableCell align="left"><IntlMessages id="process.targetId" /></TableCell>,
            <TableCell align="left"> </TableCell>
            ] : null }
          </TableRow>
        </TableHead>
        <TableBody>
          {processList.map((n,index) => {
            return (
              <TableRow key={"prc"+n.id}>
                <TableCell align="left">{(index+1)}</TableCell>
                <TableCell align="left">
                  {
                   !isAdmin && ( n.action === 14 || n.action === 15 || n.action === 16) ? "İnceleme sonucu geldi"
                  :
                  <IntlMessages id={"action."+n.action} /> 
                  }
                  
                  </TableCell>
               {showComment && <TableCell align="left">{n.userComments}</TableCell>}
                <TableCell align="left">{new Date(n.actionDate).toLocaleString()}</TableCell>
                {isAdmin ? [
                <TableCell align="left">

<Chip 
                   variant="outlined"
                    label={getFullName(n)}
                     avatar={ <Avatar alt= {n.name} src={config.userAuthApiUrl + n.profileImageUrl}/>
                     } />
 
                </TableCell>,
                <TableCell align="left">{n.targetId}</TableCell>,
                <TableCell align="left">
                       {isAdmin  && index+1 === processList.length && n.action !== 1 ?  
                   <Button
                key="btn2"
                margin="normal"
                variant="contained"
                className="jr-btn text-white bg-info"
                onClick={()=>backProcessClick(n)}
              >
               İşlemi Geri Al
              </Button> : null }
              
              </TableCell>
                ] : null } 

              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
    </div>
    </Paper>
  );
}


export default ProcessTable;