
import { SET_PUB_REVIEW_REFERE } from "../constants/ActionTypes";

const INIT_STATE = {
    pubReview:null,
    isReview: false,
    pub:null,
    
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PUB_REVIEW_REFERE: {
            return {
                ...state,
                pub: action.payload.pub,
                pubReview : action.payload.pubReview,
                isReview: action.payload.isReview,
                operating: action.payload.operating
            }
        }
        default:
            return state;
    }
}
