import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { fetchMessagessSuccess, showMessagesMessage} from 'actions/Messages';
import {FETCH_ALL_MESSAGES} from 'constants/ActionTypes';
import {  getWebMessagesByUserIdApi } from '../api/webMessages';

const getMessages = async (userId=-1) =>
 {
  //burası değişiecek şimdilik gönderilenleri çekiyor
  return  await getWebMessagesByUserIdApi(userId).catch(error=> error)

}

function* fetchMessagesRequest({payload}) {
  try {
  //console.log("getmesages payload ",payload)
    const userId = payload;
    const fetchedTodo = yield call(getMessages,userId);

   //console.log("fetchedTodo ", fetchedTodo)
    if(fetchedTodo.status)
    yield put(fetchMessagessSuccess(fetchedTodo.data));
    else
    yield put(showMessagesMessage(fetchedTodo.message));
  } catch (error) {
    yield put(showMessagesMessage(error));
  }
}



export function* fetchMessages(userId) {
  yield takeEvery(FETCH_ALL_MESSAGES, fetchMessagesRequest);
}

export default function* rootSaga() {
  yield all([ fork(fetchMessages)]);
}