import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Alert } from "@material-ui/lab";
import {
  hideMessage,
  showAuthLoader,



  userSignIn
} from "actions/Auth";
import CardBox from "components/CardBox";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isIOS, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { getPubByUidApi } from "../api/util";
import ProcessTable from "../app/routes/Component/process/ProcessTable.";


class Pub extends React.Component {
  constructor() {
    super();
    this.state = {
      pub: {},
      lock: true,
      isLangInput: false,
      fileList: [],
      process: [],
      error:false,
    };
  }

  async componentDidMount() {
    const uid = this.props.match.params.uid;

    const result = await getPubByUidApi(uid);
    if (result.status) {
    // //console.log("444444444444444 ", result);
      this.setState({ pub: result.data.pub, process: result.data.process });
    }


  }




  render() {
    const { pub, lock, process } = this.state;

    if (isIOS || isMobile) {
      document.body.classList.add("ios-mobile-view-height");
   //  //console.log("--------------------------------------------- ",isIOS, isMobile)
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
   //  //console.log("--------------------------------------------- ",isIOS, isMobile)
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
   
   
       
            {!lock ? (
              <div className="overflow-auto">
                <div className="row justify-content-center mb-3">
                  <Link to="/">
                    <img
                      src={require("assets/images/yaysis_logo/sonLogo2.png")}
                      alt="Yaysis"
                      title="Yayın Takip Sistemi"
                    />
                  </Link>
                </div>

                <CardBox
                  styleName="col-md-12 col-xs-12 col-sm-12"
                  heading={<IntlMessages id="pub.information" />}
                >
                  <List dense={true}>
                    <Divider light />

                    <ListItem>
                      <ListItemText
                        color="teal"
                        primary={<IntlMessages id="pub.title" values={{lang:""}}  />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={pub.title}
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pub.writer" />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                          Array.isArray(pub.writers)
                            ? pub.writers.join(" , ")
                            : pub.writers
                        }
                      />
                    </ListItem>
                    <Divider light />
                    <ListItem>
                      <ListItemText
                        primary={<IntlMessages id="pub.keyword" values={{lang:""}} />}
                        primaryTypographyProps={{ color: "secondary" }}
                        secondary={
                          Array.isArray(pub.keyword)
                            ? pub.keyword.join(" , ")
                            : pub.keyword
                        }
                      />
                    </ListItem>
                    <Divider light />
                  </List>
                </CardBox>

                <ProcessTable
                  processList={process}
                  isAdmin={false}
                  showComment={false}
                  classStyle="overflow-auto col-md-12 col-xs-12 col-sm-12"
                />
              </div>
            ) : (
              <div className="bg-grey"></div>
            )} 
   </div>
            <SweetAlert
              show={this.state.lock}
              input
              confirmBtnText={<IntlMessages id="button.ok" />}
              confirmBtnBsStyle="danger"
              title={<IntlMessages id="showPub.alert.title" />}
              validationMsg={<IntlMessages id="showPub.alert.msg" />}
              onConfirm={(text) => {
             //  //console.log("tettt ", text);
                if (Number(text) === pub.id) 
                this.setState({ lock: false });
                else 
                this.setState({error:true})
              }}
              //  style={{backgroundColor:'red'}}
              hideOverlay={false}
               
            >


              <div className="row justify-content-center mb-3">
                <Link className="" to="/">
                  <img
                    src={require("assets/images/yaysis_logo/sonLogo2.png")}
                    alt="Yaysis"
                    title="Yayın Takip Sistemi"
                  />
                </Link>
              </div>

              {
                this.state.error && <Alert className="mb-2" severity="error">
<IntlMessages id="showPub.alert.error" />
                </Alert>
              }
            </SweetAlert>
{/* 
            <SweetAlert
              show={this.state.error}
              danger
              confirmBtnText={<IntlMessages id="button.ok" />}
              confirmBtnBsStyle="danger"
              title={<IntlMessages id="showPub.alert.error" />}
              onConfirm={() => {
                this.setState({error:false})
              }}
            
            /> */}
            

          </div>
 
  
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(Pub);
