import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
//import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import ColorOption from "../containers/Customizer/ColorOption";
 
class App extends React.Component {


  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
      console.log("--------------------------------------------- ",isIOS, isMobile)
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`${match.url}/mainPage`}
                  component={asyncComponent(() => import("./routes/MainPage"))}
                />
                <Route
                  exact
                  path={`${match.url}/`}
                  component={asyncComponent(() => import("./routes/MainPage"))}
                />

                <Route
                  path={`${match.url}/myRefereePage`}
                  component={asyncComponent(() =>
                    import("./routes/referee/MyRefereePage")
                  )}
                />
 

<Route
                  path={`${match.url}/itemPrinciplesPage`}
                  component={asyncComponent(() =>
                    import("./routes/public/PublicationPrinciplesPage")
                  )}
                />

                <Route
                  path={`${match.url}/myBooksPage`}
                  component={asyncComponent(() =>
                    import("./routes/writer/MyBooksPage")
                  )}
                />

                <Route
                  path={`${match.url}/myArticlePage`}
                  component={asyncComponent(() =>
                    import("./routes/writer/MyArticlesPage")
                  )}
                />
                <Route
                  path={`${match.url}/sendBookPage`}
                  component={asyncComponent(() =>
                    import("./routes/writer/SendBookPage")
                  )}
                />
  <Route
                  path={`${match.url}/educationPage`}
                  component={asyncComponent(() =>
                    import("./routes/public/Education")
                  )}
                />
                <Route
                  path={`${match.url}/sendArticlePage`}
                  component={asyncComponent(() =>
                    import("./routes/writer/SendArticlePage")
                  )}
                />
                <Route
                  path={`${match.url}/assignedPubPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/AssignedPubPage")
                  )}
                />
                <Route
                  path={`${match.url}/beforeAssignedPubPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/BeforeAssignedPubPage")
                  )}
                />
                <Route
                  path={`${match.url}/inProgressPage/:a`}
                  component={asyncComponent(() =>
                    import("./routes/editor/InProgressPage")
                  )}
                />
                 <Route
                  path={`${match.url}/inProgressPage`}
                  component={asyncComponent(() =>
                    import("./routes/editor/InProgressPage")
                  )}
                />
                <Route
                  path={`${match.url}/publicationCommisionPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/PublicationCommissionPage")
                  )}
                />
                <Route
                  path={`${match.url}/roleManagementPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/RoleManagementPage")
                  )}
                />
                <Route
                  path={`${match.url}/searchUserProfile`}
                  component={asyncComponent(() =>
                    import("./routes/public/SearchUserProfile")
                  )}
                />
                <Route
                  path={`${match.url}/showUserProfile/:id`}
                  component={asyncComponent(() =>
                    import("./routes/public/ShowUserProfile")
                  )}
                />
                <Route
                  path={`${match.url}/pubReview/:id`}
                  component={asyncComponent(() =>
                    import("./routes/referee/PubReviewPage")
                  )}
                />
                <Route
                  path={`${match.url}/inInstitutions/:a`}
                  component={asyncComponent(() =>
                    import("./routes/editor/InInstitutionsPage")
                  )}
                />
                 <Route
                  path={`${match.url}/inInstitutions`}
                  component={asyncComponent(() =>
                    import("./routes/editor/InInstitutionsPage")
                  )}
                />
                 <Route
                  path={`${match.url}/royaltyCalculationPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/RoyaltyCalculationPage")
                  )}
                />
               <Route
                  path={`${match.url}/webMessagesDetailPage`}
                  component={asyncComponent(() =>
                    import("./routes/public/webMessages/WebMessagesDetail")
                  )}
                />
  <Route
                  path={`${match.url}/webMessagesPanelPage/:id/:questionId`}
                  component={asyncComponent(() =>
                    import("./routes/public/webMessages/MessagesPanel")
                  )}
                />
                <Route
                  path={`${match.url}/statisticsPage`}
                  component={asyncComponent(() =>
                    import("./routes/hightCoordinator/StatisticsPage")
                  )}
                />
  <Route
                  path={`${match.url}/journalManagementPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/JournalManagementPage")
                  )}
                />
                  <Route
                  path={`${match.url}/publicationBoardPage`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/PublicationBoardPage")
                  )}
                />
  <Route
                  path={`${match.url}/pubPrintingPage`}
                  component={asyncComponent(() =>
                    import("./routes/spendingOfficer/PubPrintingPage")
                  )}
                />
  <Route
                  path={`${match.url}/bookPrintingPage`}
                  component={asyncComponent(() =>
                    import("./routes/spendingOfficer/BookPrintingPage")
                  )}
                />

  <Route
                  path={`${match.url}/articlePrintingPage`}
                  component={asyncComponent(() =>
                    import("./routes/spendingOfficer/ArticlePrintingPage")
                  )} 
                />
  <Route
                  path={`${match.url}/journalPrintingPage`}
                  component={asyncComponent(() =>
                    import("./routes/spendingOfficer/JournalPrintingPage")
                  )}
                />
   <Route
                  path={`${match.url}/updatePubPage`}
                  component={asyncComponent(() =>
                    import("./routes/writer/UpdatePubPage")
                  )}
                />
                 <Route
                  path={`${match.url}/publicationPrinciplesPage`}
                  component={asyncComponent(() =>
                    import("./routes/public/PublicationPrinciplesPage")
                  )}
                />
                 <Route
                  path={`${match.url}/searchPub/:kw`}
                  component={asyncComponent(() =>
                    import("./routes/public/SearchPub")
                  )}
                />
                  <Route
                  path={`${match.url}/searchPub`}
                  component={asyncComponent(() =>
                    import("./routes/public/SearchPub")
                  )}
                />
                  <Route
                  path={`${match.url}/myProfile`}
                  component={asyncComponent(() =>
                    import("./routes/public/MyProfilePage")
                  )}
                />
                  <Route
                  path={`${match.url}/settings`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/SettingsPage")
                  )}
                />
                    <Route
                  path={`${match.url}/facsimile`}
                  component={asyncComponent(() =>
                    import("./routes/coordinator/FacsimilePage")
                  )}
                />
                  <Route
                  path={`${match.url}/myTranslatePage`}
                  component={asyncComponent(() =>
                    import("./routes/other/MyTranslatePage")
                  )}
                />
                    <Route
                  path={`${match.url}/myEditorialWorkPage`}
                  component={asyncComponent(() =>
                    import("./routes/other/MyEditorialWorkPage")                  )}
                />

                 <Route
                  path={`${match.url}/faq`}
                  component={asyncComponent(() =>
                    import("./routes/public/FAQ")
                  )}
                />
                     <Route
                  path={`${match.url}/messageManagementPage`}
                  component={asyncComponent(() =>
                    import("./routes/hightCoordinator/MessageManagementPage")
                  )}
                />
                <Route
                  path={`${match.url}/401`}
                  component={asyncComponent(() =>
                    import("components/Error401")
                  )}
                />
                   <Route
                  path={`${match.url}/403`}
                  component={asyncComponent(() =>
                    import("components/Error403")
                  )}
                />
                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption></ColorOption>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
