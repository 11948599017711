import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import {
  fetchMessages
} from 'actions/Messages';
import {
  fetchNotifications,
  addNewNotification,
  setAllReadNotification, setReadNotification
} from 'actions/Notifications';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import IntlMessages from 'util/IntlMessages';
import { putUpdateNotificationApi, putUpdateNotificationByUserIdApi } from '../../api/notification';
import NewMessageModal from '../../app/routes/public/webMessages/NewMessageModal';
import ReadMessageModal from '../../app/routes/public/webMessages/ReadMessageModal';
import config from '../../config';
import { history } from '../../store';
import AppNotification from '../AppNotification';
import MailNotification from '../MailNotification';
import io from 'socket.io-client';
import { Chip, Divider } from '@material-ui/core';


 
class Header extends React.Component {



  refreshMessage =  (userId)=>{
  const messages = this.props.messages;

  if((this.props.authUser === null && userId>0) || this.props.authUser !== null)
  this.props.fetchMessages(this.props.authUser === null ? userId :this.props.authUser.id );

   if(messages.newMessageSize !== null && messages.newMessageSize !== 0)
    NotificationManager.success(messages.newMessageSize + ' Yeni Mesajınız var' 
  //   , "", 5000, () => {
  //        history.push("/yts/webMessagesPanelPage/0/0")
  // }
  );

}
refreshNotifications =  async(userId)=>{
  const notifications = this.props.notifications;

  if((this.props.authUser === null && userId>0) || this.props.authUser !== null)
await this.props.fetchNotifications(this.props.authUser === null ? userId :this.props.authUser.id );

// if(notifications.newNotificationSize !== null && notifications.newNotificationSize !== 0)
// NotificationManager.info(notifications.newNotificationSize + ' Yeni Bildirim var' )

// notifications.noReadNotifications.map(notification=>
//  {
//   console.log("***** notifications ** ",notification ) 
//   NotificationManager.warning(notification.body , "", 5000 , ()=>
//     //console.log("Notiye tıklandı ",notification)
//    this.setReadNotification(notification)
    
// )
// }
// )

const notification = notifications.noReadNotifications[0]  

if(notification !== undefined)
{
  //console.log("***** notifications ** ",notification ) 
  NotificationManager.warning(notification.body 
    //, "", 
 // 5000 , 
 // ()=>    this.setReadNotification(notification)
  )

}
   
 

} 
componentDidMount = ()=>{
   this.refreshNotifications();
   this.refreshMessage();
  // this.timer = setInterval(() => this.refreshMessage(), config.messageRefreshTime);
  // this.timer2 = setInterval(() => this.refreshNotifications(), config.notificationRefreshTime);



  //config.apiUrl
  const socket = io.connect(config.appUrl , { 
    transports: [
       'websocket' ,
       'polling',
      // 'flashsocket'
    ],
    withCredentials: true
  
  })

  socket.on('message',({name,message})=>{ 
   
   //console.log("socket messages çalıştı")
   //console.log("Header componentDidUpdate name message **** ",name,message )

    if(message.receiverUserId === this.props.authUser.id)
    {
   

      NotificationManager.success(message.body,"Yeni Mesaj  "
      //, 5000, () => {
      //  history.push("/yts/webMessagesPanelPage/0/0")
       // }
        );
        this.refreshMessage(this.props.authUser.id);
    }
  }
    )

    
      socket.on('notification',({notification})=>{

     //  //console.log("He  notificatione **** ",notification )
  
        if(notification.userId === this.props.authUser.id)
        {
          this.props.addNewNotification(notification)

          this.refreshNotifications(notification.userId );
      //    NotificationManager.success(notification.body,"Yeni Bildirim ");
          
        }
      }
        )


}
// componentWillUnmount(){
//   clearInterval(this.timer);
//   this.timer = null;

//   clearInterval(this.timer2);
//   this.timer2 = null;
// }

setReadNotification = async(notification)=>{
  await putUpdateNotificationApi({id:notification.id , status : 1,userId: notification.userId });
  this.props.setReadNotification(notification);
}

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onRolesSelect = () => {
    this.setState({
      roles: !this.state.roles
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,

      newMessageDailogStatus :false,
      readMessageDailogStatus :false,
      selectedReadMessage:null,
    }
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">

<li className="jr-list-item">
            <Link className="jr-list-link"  to="/yts/sendArticlePage">
            <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
              <span className="jr-list-text text-center"> <IntlMessages id="sidebar.sendArticle"/></span>
            </Link>
          </li>
          <li className="jr-list-item">
            <Link className="jr-list-link"  to="/yts/sendBookPage">
            <i className="zmdi zmdi-book zmdi-hc-fw"/>
              <span className="jr-list-text text-center"> <IntlMessages id="sidebar.sendBook"/></span>
            </Link>
          </li>

        <li className="jr-list-item">
            <Link className="jr-list-link" to="/yts/myArticlePage">
            <i className="zmdi zmdi-file-text zmdi-hc-fw"/>
                <span className="jr-list-text"><IntlMessages id="sidebar.myArticles"/></span>
            </Link>
        </li>
        <li className="jr-list-item">
            <Link className="jr-list-link" to="/yts/myBooksPage">
            <i className="zmdi zmdi-book zmdi-hc-fw"/>
                <span className="jr-list-text"><IntlMessages id="sidebar.myBooks"/></span>
            </Link>
        </li>
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/yts/myRefereePage">
          <i className="zmdi zmdi-rss zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.myReferee"/></span>
          </Link>
        </li>
        <li className="jr-list-item">
          <Link className="jr-list-link" to="#" onClick={()=>this.setState({newMessageDailogStatus:true})}>
          <i className="zmdi zmdi-mail-send zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="webMessages.newMessageModal.title"/></span>
          </Link>
        </li>


      </ul>)
  };

  render() {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    const userRoles = this.props.authUser !== null ? this.props.authUser.userRoles : [];
    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ?
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
            </div>
            :
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                        onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon"/>
            </IconButton>
          }
  <Link className="mr-2 d-none d-sm-block"
  
  // style={{backgroundColor:'white' , padding:4 , borderRadius:5 , height:'90%'  ,
  //    WebkitBoxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
  //   boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)'
   // }}
     to="/">
            <img src={require("assets/images/yaysis_logo/sonLogo2.png")} style={{width:'100px'}} alt="Yaysis" title="Yayın Takip Sistemi"/>
          </Link>

          <img src={require("assets/images/100.png")} style={{ marginLeft:5, width:'100px'}} alt="Yaysis" title="Yayın Takip Sistemi"/>


          {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img src={require("assets/images/yaysis_logo/yaysis_logo2.png")} alt="Yaysis" title="Yayın Takip Sistemi"/>
          </Link> */}

{/* 
          <SearchBox styleName="d-none d-lg-block" placeholder=""
                     onChange={this.updateSearchText.bind(this)}
                     value={this.state.searchText}/> */}
          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
          <Menu/>}
 
          <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.roles}
                toggle={this.onRolesSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-lg"/>
                    <span>Kayıtlı Roller</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                {
           userRoles.filter(x=>x.id>50 && x.id < 60).map((role,index)=><Chip   variant="outlined" size="small" key={"key"+role.id} label={role.name.split("-")[1]} className="m-1" color={index % 2 === 0 ? 'secondary' :'primary'} />)
          }
                </DropdownMenu>
              </Dropdown>
            </li>

            <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.apps}
                toggle={this.onAppsSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span><IntlMessages id="header.shortcuts"/></span>
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  {this.Apps()}
                </DropdownMenu>
              </Dropdown>
            </li>
            {/* <li className="d-inline-block d-lg-none list-inline-item">
              <Dropdown
                className="quick-menu nav-searchbox"
                isOpen={this.state.searchBox}
                toggle={this.onSearchBoxSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-search zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="p-0">
                  <SearchBox styleName="search-dropdown" placeholder=""
                             onChange={this.updateSearchText.bind(this)}
                             value={this.state.searchText}/>
                </DropdownMenu>
              </Dropdown>
            </li> */}
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                    handleRequestClose={this.handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>


            </li>
             <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.appNotification}
                toggle={this.onAppNotificationSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                  <Badge badgeContent={this.props.notifications.newNotificationSize} color="error">

                    <i className="zmdi zmdi-notifications-none  animated infinite wobble"/>
                    </Badge>

                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  {/* <CardHeader styleName="align-items-center"
                              heading={<IntlMessages id="notification.title"/>}/> */}
                      <div className={`jr-card-header d-flex align-items-start align-items-center`}>
                            <div className="mr-auto">

                              <h3 className="card-heading pointer" onClick={
                                ()=>    {
                                  this.onMailNotificationSelect()
                                  history.push(`/yts/webMessagesPanelPage/0/0`);
                                }
 
                              }>{<IntlMessages id="notification.title"/>}</h3>
                              {/* {subHeading && <p className="sub-heading">{subHeading}</p>} */}
                            </div>
                    
                            {this.props.notifications.newNotificationSize > 0 &&
                            <Button className="jr-btn jr-btn-xs text-muted bg-primary"
                             onClick={
                              async ()=>{
                                await putUpdateNotificationByUserIdApi({status : 1},this.props.authUser.id);
         
                                this.props.setAllReadNotification();
                            }
                             }>
                               <i className="zmdi zmdi-notifications-off"/><span>
                            <IntlMessages id="button.isReadAll"/></span></Button> }

                               

                          </div>
                  <AppNotification 
                  notifications={this.props.notifications.noReadNotifications} 
                  setReadNotification={
                    async (notification)=>{
                        await putUpdateNotificationApi({id:notification.id , status : 1 ,userId: notification.userId  });

                        this.props.setReadNotification(notification);
                    }
                   }
                  
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.mailNotification}
                toggle={this.onMailNotificationSelect}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                  <IconButton className="icon-btn">
                 {this.props.newMessageSize > 0 ? <Badge badgeContent={this.props.newMessageSize} color="error">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                    </Badge> : <Badge color="error">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                    </Badge> }

                  </IconButton>  
                </DropdownToggle>
 
 
                <DropdownMenu right>
                  {/* <CardHeader  styleName="align-items-center"   heading={<IntlMessages id="mailNotification.title"/>}/> */}

                  <div className={`jr-card-header d-flex align-items-start align-items-center`}>
                            <div className="mr-auto">

                              <h3 className="card-heading pointer" onClick={
                                ()=>    {
                                  this.onMailNotificationSelect()
                                  history.push(`/yts/webMessagesPanelPage/0/0`);
                                }

                              }>{<IntlMessages id="mailNotification.title"/>}</h3>
                              {/* {subHeading && <p className="sub-heading">{subHeading}</p>} */}
                            </div>
                    
                           
                            <Button className="jr-btn jr-btn-xs text-muted bg-primary"
                             onClick={()=>this.setState({newMessageDailogStatus:true})}>
                               <i className="zmdi zmdi-mail-send"/><span>
                                 <IntlMessages id="webMessages.newMessageModal.title"/></span></Button>

                              

                          </div>

                  <MailNotification 
                  onMailNotificationSelect={this.onMailNotificationSelect.bind(this)}
                  selectedReadMessage={
                    (selectedReadMessage)=>this.setState({selectedReadMessage , readMessageDailogStatus:true})}
                  /> 
                </DropdownMenu>
              </Dropdown>
              <NewMessageModal open={this.state.newMessageDailogStatus} senderUser={this.props.authUser}
                   receiverUser={null} newMessageType="detail" onClose={()=>this.setState({newMessageDailogStatus:false})}  isAuthorized={false}/>
                 
             {this.state.selectedReadMessage !== null 
             && 
             <ReadMessageModal
                          open={this.state.readMessageDailogStatus}
                          //updateMessages={updateMessages}
                            selectedReplyMessage ={(selectedMessage)=> {
                              this.setState({readMessageDailogStatus : false})
                              history.push(`/yts/webMessagesPanelPage/${selectedMessage.id}/${selectedMessage.questionId}`)
                            } }
                          receiverUser={this.props.authUser}
                            senderUser={this.state.selectedReadMessage}
                            selectedMessage={this.state.selectedReadMessage}
                              onClose={()=>this.setState({readMessageDailogStatus : false})}
                      />}
      
            </li> 

            {navigationStyle === HORIZONTAL_NAVIGATION &&
            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn size-30">
                    <Avatar
                      alt='...'
                      src={'https://via.placeholder.com/150x150'}
                      className="size-30"
                    />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfoPopup/>
                </DropdownMenu>
              </Dropdown>
            </li>}
          </ul>
          
          <div className="ellipse-shape"></div>
        </Toolbar>
      </AppBar>
    );
  }

}


const mapStateToProps = ({settings,messages,auth,notifications}) => {
  const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
  const {newMessageSize} = messages;
  
  const {authUser} = auth;
  return {drawerType, locale, navigationStyle, horizontalNavPosition,newMessageSize,authUser,messages,notifications}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage,fetchMessages,fetchNotifications,setReadNotification,setAllReadNotification,addNewNotification})(Header));